import React from 'react';
import PropTypes from 'prop-types';
// import './DetailsShelfDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ListSubheader from '@material-ui/core/ListSubheader';

import S_Datas   from   '../services/Datas';

function DetailsShelfDialog(props) {

  let defaultName = props.shelf ? props.shelf.i18n.fr : '';
  let defaultStoreID = props.shelf ? props.shelf.storeID : [];

  return (
    <Dialog fullWidth={true} open={props.open} onClose={props.onClose} onBackdropClick={props.onClose} aria-labelledby="detailsitem-dialog-title">
      <DialogContent>

        <h2 id="detailsitem-dialog-title">Modifier le rayon</h2>

        <div className="field">
          <FormControl fullWidth>
            <TextField id="name" label="Name" value={defaultName} variant="outlined" fullWidth onChange={props.onChangeName}/>
          </FormControl>
        </div>
        <div className="field">
          <FormControl variant="outlined" className="formcontrol-multiselect">
            <InputLabel id="choosestore-label">Magasin</InputLabel>
            <div className="select">
              <Select labelId="choosestore-label" label="Magasin" id="choosestore-checkbox" value={defaultStoreID} onChange={props.onChangeStore} fullWidth>
                <MenuItem value=""></MenuItem>
                {props.stores.map((store,index) => {
                  return (
                    <MenuItem key={store.id} value={store.id}>{store.i18n.fr}</MenuItem>
                  );
                })}
              </Select>
            </div>
          </FormControl>
        </div>

      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={props.onDelete} disabled={!props.deleteEnabled}>Supprimer</Button>
        <Button onClick={props.onClose} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog >
  );
    
}
  
DetailsShelfDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,

  shelf: PropTypes.any,
  stores: PropTypes.array,
  deleteEnabled: PropTypes.bool,

  onChangeName: PropTypes.func,
  onChangeStore: PropTypes.func,
  onDelete: PropTypes.func
}; 

export default DetailsShelfDialog;