import React from 'react';
import PropTypes from 'prop-types';
import './CustomChip.scss';

import Chip from '@material-ui/core/Chip';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import AdjustIcon from '@material-ui/icons/Adjust';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CategoryIcon from '@material-ui/icons/Category';
import IconButton from '@material-ui/core/IconButton';

function CustomChip(props) {

  // affichage listItem
  if(!props.variant || (props.variant!=='model' && props.variant!=='modelToAdd'))
    return (
      <div className={'chip-container' + (props.isTemp ? ' temporary' : '')}>
        { props.quantity && props.quantity!=='' ? (
        <Chip className="quantity" label={ props.quantity } variant="outlined" size="small"/>
        ) : (
        <span style={{'width':'32px'}}></span>
        ) }
        <Chip label={ props.label } icon={props.important ? <PriorityHighIcon /> : null } onClick={props.onClick} onDelete={props.onDelete}/>
        { props.notes ? (
        <span className="notes">{ props.notes.split('\n').map((words, key) => {
          return <span key={key}>{words}<br/></span>
        }) }</span>
        ) : null }
        { props.themes && props.themes.length>0 ? (
        <span className="themes">{ props.themes.map((theme, index) => {
          return <span key={theme.id}>{theme.name}{ props.themes.length>1 && index<props.themes.length-1 ? ', ' : ''}</span>
        }) }</span>
        ) : null }
      </div>
    );
    
  // affichage item
  if(props.variant==='model')
    return (
      <div className="chip-container model">
        <Chip label={ props.label } icon={props.tag.code === 'general' ? <BlurCircularIcon/> : <AdjustIcon/>} className=" MuiChip-model" onClick={props.onClick} onDelete={props.onDelete}/>
        {props.onToggle ? <IconButton className="toggleSubItemsButton" size="small" color="primary" aria-label="Sous-produits" title="Sous-produits" onClick={props.onToggle}><CategoryIcon /></IconButton> : null}
      </div>
    );

  if(props.variant==='modelToAdd')
    return (
      <div className="chip-container model">
        <Chip label={ props.label } icon={props.tag && props.tag.code === 'general' ? <BlurCircularIcon/> : <AdjustIcon/>} className=" MuiChip-model" onClick={props.onClick}/>
        {props.onToggle ? <IconButton className="toggleSubItemsButton" size="small" color="primary" aria-label="Sous-produits" title="Sous-produits" onClick={props.onToggle}><CategoryIcon /></IconButton> : null}
      </div>
    );
}

CustomChip.propTypes = {  
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onToggle: PropTypes.func,

  variant: PropTypes.string,  // - | model | modelToAdd
  isTemp: PropTypes.bool,

  label: PropTypes.string,
  quantity: PropTypes.string,
  important: PropTypes.bool,
  notes: PropTypes.string,
  themes: PropTypes.array,
  tag: PropTypes.object
};   

export default CustomChip;
