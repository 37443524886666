import React from 'react';
import PropTypes from 'prop-types';
import './CustomCard.scss';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import PlaceIcon from '@material-ui/icons/Place';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import AdjustIcon from '@material-ui/icons/Adjust';
import EuroIcon from '@material-ui/icons/Euro';
import CardActionArea from '@material-ui/core/CardActionArea';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import S_Datas   from   '../services/Datas';

function CustomCard(props) {

  return (
    <Card className={'card' + (props.isTemporary ? ' temporary' : '') + (props.isModel ? ' model' : '')}>
      { props.pictures && props.pictures.length>0 ? (
      <CardMedia
        className="media"
        image={props.pictures[0]}
        title={props.i18n.fr}
      />
      ) : null}
      <CardContent>
        <Typography variant="h5">{props.i18n.fr}</Typography>
        <div style={{'margin': '0.5rem 0'}}>
          { props.shelvesID.map((shelfID,index) => {
            let shelf = S_Datas.getShelf(shelfID);
            let store = shelf.storeID!==undefined ? S_Datas.getStore(shelf.storeID) : undefined;
            let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : null;
            return (
              <Chip icon={<PlaceIcon />} label={(store ? '['+store.i18n.fr+'] ' : '') + shelf.i18n.fr} size="small" key={shelf.id}/>
            );
          })}
        </div>
        <div style={{'margin': '0.5rem 0'}}>
          { props.tagsID.map((tagID,index) => {
            let tag = S_Datas.getTag(tagID);
            if(tagID===2){
              let main = props.mainID!==undefined ? S_Datas.getItem(props.mainID) : undefined;
              return (
                <>
                <Chip icon={tag.code === 'general' ? <BlurCircularIcon /> : <AdjustIcon />} label={tag.i18n.fr} size="small" variant="outlined" key={tag.id}/>
                {main ? <span className="mainItem">{main.i18n.fr}</span> : null}
                </>
              );
            }
            return (
              <Chip icon={tag.code === 'general' ? <BlurCircularIcon /> : <AdjustIcon />} label={tag.i18n.fr} size="small" variant="outlined" key={tag.id}/>
            );
          })}
        </div>
        { props.price!==undefined && props.price!=='' ? (
        <div>
          <EuroIcon />
          <Chip label={props.price+'€ par '+props.priceUnity} size="small" disabled={true}/>
          { props.quantity!==undefined && props.quantity!==0 ? (
            <>
            <span> x </span>
            <Chip label={props.quantity} size="small" disabled={true}/>
            <span> = </span>
            <Chip label={(props.quantity * props.price) + '€'} size="small" />
            </>
          ) : null }
        </div>
        ) : null }
      </CardContent>
      { (props.notes && props.notes!=='') || (props.themes && props.themes.length>0) || (props.important) ? (
      <CardActions>
        <div>
          {props.important ? (
            <Chip icon={<PriorityHighIcon />} label="Important, y'en a plus !" size="small"/>
          ) : null }
          { props.notes && props.notes!=='' ? (
          <Typography className="notes">{ props.notes.split('\n').map((words, key) => {
            return <span key={key}>{words}<br/></span>
          }) }</Typography>
          ) : null }
          { props.themes && props.themes.length>0 ? (
          <div className="themes">{ props.themes.map((theme, index) => {
            return <span key={theme.id}>{theme.name}{ props.themes.length>1 && index<props.themes.length-1 ? ', ' : ''}</span>
          }) }</div>
          ) : null }
        </div>
      </CardActions>   
      ) : null}   
    </Card>
  );
  
}
  
CustomCard.propTypes = {
  i18n: PropTypes.object,
  shelvesID: PropTypes.array,
  tagsID: PropTypes.array,
  pictures: PropTypes.array,
  price: PropTypes.string,
  priceUnity: PropTypes.string,
  quantity: PropTypes.number,
  isTemporary: PropTypes.bool,
  isModel: PropTypes.bool,
  important: PropTypes.bool,
  notes: PropTypes.string,
  themes: PropTypes.array,
  mainID: PropTypes.number
}; 

export default CustomCard;
