import React from 'react';
import PropTypes from 'prop-types';
// import './DetailsStoreDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ListSubheader from '@material-ui/core/ListSubheader';

import S_Datas   from   '../services/Datas';

function DetailsStoreDialog(props) {

  let defaultName = props.store ? props.store.i18n.fr : '';

  return (
    <Dialog fullWidth={true} open={props.open} onClose={props.onClose} onBackdropClick={props.onClose} aria-labelledby="detailsitem-dialog-title">
      <DialogContent>

        <h2 id="detailsitem-dialog-title">Modifier le magasin</h2>

        <div className="field">
          <FormControl fullWidth>
            <TextField id="name" label="Name" value={defaultName} variant="outlined" fullWidth onChange={props.onChangeName}/>
          </FormControl>
        </div>

      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={props.onDelete} disabled={!props.deleteEnabled}>Supprimer</Button>
        <Button onClick={props.onClose} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog >
  );
    
}
  
DetailsStoreDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,

  store: PropTypes.any,
  deleteEnabled: PropTypes.bool,

  onChangeName: PropTypes.func,
  onDelete: PropTypes.func
}; 

export default DetailsStoreDialog;