import React from 'react';
import PropTypes from 'prop-types';
// import './DetailsItemDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ListSubheader from '@material-ui/core/ListSubheader';

import AllInboxIcon from '@material-ui/icons/AllInbox';
import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/Place';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import S_Datas   from   '../services/Datas';
import CustomCard   from   '../components/CustomCard';

function compareItems(a, b) {
  if (a.i18n.fr.toLowerCase() > b.i18n.fr.toLowerCase()) return 1;
  if (b.i18n.fr.toLowerCase() > a.i18n.fr.toLowerCase()) return -1;

  return 0;
}

function DetailsItemDialog(props) {

  // console.log('Details item : ',props.item);
  // console.log('Delete enabled : ',props.deleteEnabled);

  let renderValueMultiSelect = (selected) => {
    // console.log(selected);
    if(!selected)
      return '';
    if(selected.length===0)
      return 'Aucun rayon';
    return selected.length + ' rayon(s)'; //(selected as string[]).join(', ');
  };

  let renderItemValueSelect = (selected) => {
    // console.log(selected);
    if(!selected)
      return '';
    if(selected.length===0)
      return 'Aucun produit';
    console.log(selected);
    return S_Datas.getItem(selected).i18n.fr;
  };

  // tri des rayons par ordre alpha, sur category puis sur i18n
  let shelvesClone = JSON.parse(JSON.stringify(props.shelves));
  let shelvesSorted = shelvesClone.sort(compareItems);

  // sans store
  let noStoreShelves = [];
  if(props.shelves && props.shelves.length>0){
    for(var i = 0; i < props.shelves.length; i++){
      let shelf = props.shelves[i];
      if(shelf.storeID===undefined || S_Datas.getStore(shelf.storeID)===undefined){
        noStoreShelves.push(shelf);
        continue;
      }
    }
  }

  let itemsSorted = [];
  if(props.items && props.items.length>0){
    itemsSorted = JSON.parse(JSON.stringify(props.items)).sort(compareItems);
  }

  let defaultName = props.item ? props.item.i18n.fr : '';
  let defaultBarcode = props.item ? props.item.barcode : '';
  let defaultShelves = props.item ? props.item.shelvesID : [];
  let defaultTag = props.item && props.item.tagsID.length>0 ? props.item.tagsID[0] : 1;
  let defaultPrice = props.item ? props.item.price : 0;
  let defaultPriceUnity = props.item ? props.item.priceUnity : '1L';
  let defaultMain = props.item ? props.item.itemID : undefined;

  return (
    <Dialog fullWidth={true} open={props.open} onClose={props.onClose} onBackdropClick={props.onClose} aria-labelledby="detailsitem-dialog-title">
      <DialogContent>

        { props.item ? (
        <CustomCard 
          i18n={props.item.i18n}
          pictures={props.item.pictures}
          shelvesID={props.item.shelvesID}
          tagsID={props.item.tagsID}
          price={props.item.price}
          priceUnity={props.item.priceUnity}
          isModel={true}
          mainID={props.item.itemID}
          />
        ) : null }

        <h2 id="detailsitem-dialog-title">Modifier le produit modèle</h2>
        

        <div className="field">
          <FormControl fullWidth>
            <TextField id="outlined-multiline-static" label="Name" value={defaultName} variant="outlined" fullWidth onChange={props.onChangeName}/>
          </FormControl>
        </div>
        
        <div className="field">
          <FormControl variant="outlined" className="formcontrol-multiselect">
            <InputLabel id="chooseshelves-label">Rayons</InputLabel>
            <div className="multiselect">
              <Select labelId="chooseshelves-label" label="Rayons" id="chooseshelves-checkbox" multiple value={defaultShelves} onChange={props.onChangeShelves} 
                input={<Input />} renderValue={renderValueMultiSelect} fullWidth>
                {props.stores && props.stores.length>0 ? props.stores.map((store,index) => {
                  return [
                    <ListSubheader key={'S-'+index}><PlaceIcon />{ store.i18n.fr }</ListSubheader>,
                    props.shelves && props.shelves.length>0 ? props.shelves.map( (shelf,index2) => {
                      if(shelf.storeID !== store.id)
                        return null;
                      let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : undefined;
                      return (
                        <MenuItem key={'S-'+index + '-' + index2} value={shelf.id}>
                          <Checkbox checked={props.item ? props.item.shelvesID.indexOf(shelf.id) > -1 : false} />
                          <ListItemText primary={(shelfCategory ? '['+shelfCategory.i18n.fr+'] ':'')+shelf.i18n.fr} />
                        </MenuItem>
                      );
                    }) : null
                  ];
                }) : null}

                {noStoreShelves && noStoreShelves.length>0 ? [
                  <ListSubheader key={0}>Sans magasin</ListSubheader>,,
                  noStoreShelves.map( (shelf,index) => {
                    let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : undefined;
                    return (
                      <MenuItem key={'W-'+index} value={shelf.id}>
                        <Checkbox checked={props.item ? props.item.shelvesID.indexOf(shelf.id) > -1 : false} />
                        <ListItemText primary={(shelfCategory ? '['+shelfCategory.i18n.fr+'] ':'')+shelf.i18n.fr} />
                      </MenuItem>
                    );
                  })
                ] : null}
              </Select>
            </div>
          </FormControl>
        </div>

        <div className="field">
          <FormControl variant="outlined" className="formcontrol-multiselect">
            <InputLabel id="choosetags-label" className="MuiInputLabel-shrink">Type</InputLabel>
            <div className="multiselect">
              <RadioGroup aria-label="Tags" name="tag" value={defaultTag} onChange={props.onChangeTags}>
                {props.tagsOptions.map((tag,index) => (
                  <FormControlLabel key={tag.id} value={tag.id} control={<Radio />} label={tag.i18n.fr} />
                ))}
              </RadioGroup>
              {defaultTag===2 ? (
              <div className="multiselect">
                <div>Sous-produit de :</div>
                <Select labelId="choosemain-label" label="Produit" id="choosemain-checkbox" value={defaultMain} onChange={props.onChangeMain} 
                  input={<Input />} renderValue={renderItemValueSelect} fullWidth>
                  <MenuItem key={'S-0'}>
                    <Checkbox checked={props.item && props.item.mainID===undefined ? true : false} />
                    <ListItemText primary={''} />
                  </MenuItem>
                  {itemsSorted.map((main,index) => {
                    if(main.tagsID.indexOf(1)===-1)
                      return null;
                    return (
                      <MenuItem key={'S-'+index} value={main.id}>
                        <Checkbox checked={props.item && props.item.mainID!==undefined ? props.item.mainID===main.id : false} />
                        <ListItemText primary={main.i18n.fr} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              ) : null}
            </div>
          </FormControl>
        </div>

        <div className="field">
          <FormControl fullWidth>
            <TextField type="number" id="barcode" label="Code barre" value={defaultBarcode} variant="outlined" fullWidth onChange={props.onChangeBarCode}/>
          </FormControl>
          { props.scannerEnabled && props.onAskScan ? (
          <div className={'scannerWrapper'+(props.scannerRunning? ' running' : '')}>
            <div id="scanner"></div>
            { !props.scannerRunning ? <div><Button fullWidth onClick={props.onAskScan}>Scanner un code barre</Button></div> : null }
            { props.scannerRunning ? <div><Button variant={'contained'} fullWidth onClick={props.onStopScan}>Stop</Button></div> : null }
          </div>
          ) : null }
          { props.onAskOpenFoodFact!==undefined ? <div><Button fullWidth onClick={props.onAskOpenFoodFact}>Importer les données Open Food Fact pour {defaultBarcode}</Button></div> : null }
          { props.alertScan!==undefined ? <div className="alert"><Button onClick={props.onCloseAlertScan}>x</Button>{props.alertScan}</div> : null }
          { props.item ? <div><Button fullWidth href={'https://google.com/search?q='+props.item.i18n.fr+' '+defaultBarcode+' carrefour'} target="_blank">Rechercher sur Google</Button></div> : null }
        </div>

        <div className="field">
          <FormControl fullWidth>
            <TextField type="number" id="price" label="Prix moyen en €" value={defaultPrice} variant="outlined" fullWidth onChange={props.onChangePrice}/>
          </FormControl>
          <br/><br/>
          <FormControl fullWidth>
            <TextField type="text" id="priceUnity" label="Par" value={defaultPriceUnity} variant="outlined" fullWidth onChange={props.onChangePriceUnity}/>
          </FormControl>
        </div>

      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={props.onDelete} disabled={!props.deleteEnabled}>Supprimer</Button>
        <Button onClick={props.onClose} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog >
  );
    
}

DetailsItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,

  item: PropTypes.any,
  items: PropTypes.array,
  shelves: PropTypes.array,
  shelvesCategories: PropTypes.array,
  tagsOptions: PropTypes.array,
  stores: PropTypes.array,
  deleteEnabled: PropTypes.bool,

  onChangeName: PropTypes.func,
  onChangeShelves: PropTypes.func,
  onChangeStore: PropTypes.func,
  onChangeTags: PropTypes.func,
  onChangeBarCode: PropTypes.func,
  onChangePrice: PropTypes.func,
  onChangePriceUnity: PropTypes.func,
  onChangeMain: PropTypes.func,
  onDelete: PropTypes.func,

  onAskScan: PropTypes.func,
  onStopScan: PropTypes.func,
  scannerEnabled: PropTypes.bool,
  scannerInitialized: PropTypes.bool,
  scannerRunning: PropTypes.bool,
  onAskOpenFoodFact: PropTypes.func,
  alertScan: PropTypes.func,
  onCloseAlertScan: PropTypes.func
}; 

export default DetailsItemDialog;