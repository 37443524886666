import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import PlaceIcon              from '@material-ui/icons/Place';
import AllInboxIcon           from '@material-ui/icons/AllInbox';
import CategoryIcon           from '@material-ui/icons/Category';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FastfoodIcon           from '@material-ui/icons/Fastfood';
import MoreVertIcon           from '@material-ui/icons/MoreVert';

// import './AppBarDefault.scss';

function AppBarDefault(props) {
  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton aria-label="Listes" title="Listes" 
          color={!props.onViewDefault ? 'secondary' : 'inherit'} onClick={props.onViewDefault}>
          <FormatListBulletedIcon />
          <div className="iconButtonLegend">Listes</div>
        </IconButton>
        <Typography variant="h6" className="title"></Typography>
        { false ? <IconButton aria-label="Magasins" title="Magasins"
          color={!props.onViewStores ? 'secondary' : 'inherit'} onClick={props.onViewStores}>
          <PlaceIcon />
          <div className="iconButtonLegend">Magasins</div>
        </IconButton> : null }
        <IconButton aria-label="Rayons" title="Rayons"
          color={!props.onViewShelves ? 'secondary' : 'inherit'} onClick={props.onViewShelves}>
          <AllInboxIcon />
          <div className="iconButtonLegend">Rayons</div>
        </IconButton>
        <IconButton aria-label="Catalogue" title="Catalogue"
          color={!props.onViewItems ? 'secondary' : 'inherit'} onClick={props.onViewItems}>
          <FastfoodIcon />
          <div className="iconButtonLegend">Catalogue</div>
        </IconButton>
        {false ? (
        <IconButton aria-label="Plus" color="inherit" ref={props.homeMenuAnchorRef} onClick={props.onToggleHomeMenu}>
          <MoreVertIcon />
        </IconButton>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}
  
AppBarDefault.propTypes = {
  onViewDefault: PropTypes.func,
  onViewStores: PropTypes.func,
  onViewShelves: PropTypes.func,
  onViewItems: PropTypes.func,

  homeMenuAnchorRef: PropTypes.any,
  onToggleHomeMenu: PropTypes.func
};

export default AppBarDefault;
