import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Fab from '@material-ui/core/Fab';
import CategoryIcon from '@material-ui/icons/Category';
import AddIcon from '@material-ui/icons/Add';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PlaceIcon from '@material-ui/icons/Place';

// import S_Datas   from   '../services/Datas';
import Items     from   '../components/Items';

import './Catalog.scss';

function Catalog(props) {

  return (
    <Container className="main catalog">

      <div className="mode-tabs">
        <ButtonGroup size="small" aria-label="Affichage" color="primary">
          <Button variant={props.orderItemsBy === 'names' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderItems(e,0)} aria-label="Par nom" title="Par nom"><TextFormatIcon /></Button>
          <Button variant={props.orderItemsBy === 'shelves' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderItems(e,1)} aria-label="Par rayon" title="Par rayon"><PlaceIcon /></Button>
        </ButtonGroup>
      </div>
      <div className="mode-contents">

        { props.items.length === 0 ? (
        <div className="empty-contents">
          <div className="text">Le catalogue est vide. <br/>Ajoutez une référence</div>
          <Button variant="contained" aria-label="Ajouter une référence" title="Ajouter une référence" onClick={(e) => {props.onAddItem(e);}}>Ajouter une référence</Button>
        </div>
        ) : (
        <div>
          { props.orderItemsBy === 'names' ? (
          <div id={'second-tabpanel-0'} aria-labelledby={'second-tab-0'}>
            <Items 
              items={props.items} 
              editable={false} 
              onAdd={props.onAddItemToList} 
              onToggleExpandItem={props.toggleExpandItem}/>
          </div>
          ) : null }
          { props.orderItemsBy === 'shelves' ? (
          <div id={'second-tabpanel-1'} aria-labelledby={'second-tab-1'}>
            <Items 
              items={props.items} 
              variant="shelves" 
              shelves={props.shelves} 
              stores={props.stores} 
              editable={false} 
              onAdd={props.onAddItemToList} 
              onToggleExpandItem={props.toggleExpandItem}/>
          </div>
          ) : null }
        </div>
        ) }
        
        {props.displayFabs ? [
        <Fab color="primary" aria-label="Ajouter un produit temporaraire" title="Ajouter un produit temporaraire" size="small" className="fab-right fab-right-3 MuiFab-temporary" onClick={(e) => {props.onAddItemTemporary(e);props.onToggleSelectItemDrawer()}}>
          <CategoryIcon  />
          <div className="iconButtonLegend">Temp.</div>
        </Fab>,
        <Fab color="primary" aria-label="Ajouter un produit" title="Ajouter un produit" size="small" className="fab-right fab-right-2 MuiFab-model" onClick={(e) => {props.onAddItem(e);}}>
          <CategoryIcon  />
          <div className="iconButtonLegend">Mod.</div>
        </Fab>
        ] : null}
        <Fab color="primary" aria-label="Ajouter un produit" title="Ajouter un produit" className="fab-right fab-right-1" onClick={props.onToggleFabs}>
          <AddIcon />
        </Fab>
      </div>
      
    </Container>
  );
}
  
Catalog.propTypes = {
  orderItemsBy: PropTypes.string,
  onChangeOrderItems: PropTypes.func,

  items: PropTypes.any,
  shelves: PropTypes.any,
  stores: PropTypes.any,
  onAddItemToList: PropTypes.func,
  toggleExpandItem: PropTypes.func,

  displayFabs: PropTypes.bool,
  onToggleFabs: PropTypes.func,
  onAddItemTemporary: PropTypes.func,
  onAddItem: PropTypes.func,
  onToggleSelectItemDrawer: PropTypes.func
};

export default Catalog;
