import S_App    from   '../services/App';
import {I_Item}   from   '../models/I_Item';
import {DEFAULT_ITEMS,DEFAULT_LISTS,DEFAULT_KEYWORDS,DEFAULT_TAGS,DEFAULT_SHELVES,DEFAULT_SHELVESCATEGORIES,DEFAULT_STORES}   from   '../models/Constants';

import arrayMove from "array-move";

let S_Datas = {
  STORAGE_KEY_ITEMS : 'items',
  STORAGE_KEY_KEYWORDS : 'keywords',
  STORAGE_KEY_TAGS : 'tags',
  STORAGE_KEY_SHELVES : 'shelves',
  STORAGE_KEY_SHELVESCATEGORIES : 'shelvesCategories',
  STORAGE_KEY_STORES : 'stores',
  STORAGE_KEY_LISTS : 'lists',

  items : [],   
  keywords : [],    
  tags : [],      
  shelves : [], 
  shelvesCategories : [],  
  stores: [],
  lists : [],   
  

  fetch: (cb:any) => {
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- fetch()...');
    // ITEMS ----
    var items = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_ITEMS];
    if(items){
      S_Datas.items = JSON.parse(items);
    }else{
      S_Datas.items = DEFAULT_ITEMS;
    }
    //S_Datas.items = DEFAULT_ITEMS;

    // KEYWORDS ----
    S_Datas.keywords = DEFAULT_KEYWORDS;
    // TAGS ----
    S_Datas.tags = DEFAULT_TAGS;

    // SHELVES ----
    var shelves = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_SHELVES];
    if(shelves!==undefined){
      S_Datas.shelves = JSON.parse(shelves);
    }else{
      S_Datas.shelves = DEFAULT_SHELVES;
    }
    // S_Datas.shelves = DEFAULT_SHELVES;

    S_Datas.shelvesCategories = DEFAULT_SHELVESCATEGORIES;

    // LISTS ----
    var lists = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_LISTS];
    if(lists!==undefined){
      S_Datas.lists = JSON.parse(lists);
      // rajout de l'attribut listItemsTemporary
      // a supprimer apres validation sur tous les devices
      // if(S_Datas.lists && S_Datas.lists.length>0){
      //   for(var i = 0; i < S_Datas.lists.length; i++){
      //     if(!S_Datas.lists[i].listItemsTemporary)
      //       S_Datas.lists[i].listItemsTemporary = [];
      //   }
      // }
    }else{
      S_Datas.lists = DEFAULT_LISTS;
    }

    // STORES ----
    var stores = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_STORES];
    if(stores!==undefined){
      S_Datas.stores = JSON.parse(stores);
    }else{
      S_Datas.stores = DEFAULT_STORES;
    }


    // nettoyage des correspondances
    let savePlz = false;
    if(S_Datas.shelves && S_Datas.shelves.length>0){
      for(var i = 0; i < S_Datas.shelves.length; i++){
        let shelf = S_Datas.shelves[i];
        if(shelf && shelf.storeID!==undefined && S_Datas.getStore(shelf.storeID)===undefined){
          shelf.storeID = undefined;
          savePlz = true;
        }
        if(shelf && shelf.categoryID!==undefined && S_Datas.getShelfCategory(shelf.categoryID)===undefined){
          shelf.categoryID = undefined;
          savePlz = true;
        }
      }
    }
    if(S_Datas.items && S_Datas.items.length>0){
      for(var i = 0; i < S_Datas.items.length; i++){
        let item = S_Datas.items[i];
        if(item && item.shelvesID!==undefined){
          for(var j = item.shelvesID.length-1; j>= 0; j--){
            if(item.shelvesID[j]!==undefined && S_Datas.getShelf(item.shelvesID[j])===undefined){
              item.shelvesID.splice(j,1);
              savePlz = true;
            }
          }
        }
      }
    }
    if(S_Datas.lists && S_Datas.lists.length>0){
      for(var i = 0; i < S_Datas.lists.length; i++){
        let list = S_Datas.lists[i];
        if(list && list.listItemsTemporary!==undefined && list.listItemsTemporary.length>0){
          for(var j = 0; j < list.listItemsTemporary.length; j++){
            let listItemTemp = list.listItemsTemporary[j];
            if(listItemTemp && listItemTemp.shelvesID!==undefined){
              for(var k = listItemTemp.shelvesID.length-1; k>= 0; k--){
                if(listItemTemp.shelvesID[k]!==undefined && S_Datas.getShelf(listItemTemp.shelvesID[k])===undefined){
                  listItemTemp.shelvesID.splice(k,1);
                  savePlz = true;
                }
              }
            }
          }
        }
      }
    }
    if(savePlz){
      S_Datas.store(undefined);
    }
      
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- fetch() items',S_Datas.items);
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- fetch() keywords (hardcoded)',S_Datas.keywords);
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- fetch() tags (hardcoded)',S_Datas.tags);
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- fetch() shelves',S_Datas.shelves);
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- fetch() lists',S_Datas.lists);
    if(cb)
      cb();
  },
  store: (cb:any) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_ITEMS] = JSON.stringify(S_Datas.items);
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_SHELVES] = JSON.stringify(S_Datas.shelves);
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_LISTS] = JSON.stringify(S_Datas.lists);
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_STORES] = JSON.stringify(S_Datas.stores);
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- store() items',S_Datas.items); 
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- store() shelves',S_Datas.shelves); 
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- store() lists',S_Datas.lists); 
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- store() stores',S_Datas.stores); 
    if(cb)
      cb();
  },

  _getFromList: (id:number,list:Array<any>) => {
    if(!list || list.length===0 || id===undefined)
      return;
    for(var i = 0; i < list.length; i++){
      if(list[i].id === id)
        return list[i];
    }
    return;
  },

  getItem: (id:number) => {
    return S_Datas._getFromList(id,S_Datas.items);
  },
  getTag: (id:number) => {
    return S_Datas._getFromList(id,S_Datas.tags);
  },
  getKeyword: (id:number) => {
    return S_Datas._getFromList(id,S_Datas.keywords);
  },
  getShelf: (id:number) => {
    return S_Datas._getFromList(id,S_Datas.shelves);
  },
  getShelfCategory: (id:number) => {
    return S_Datas._getFromList(id,S_Datas.shelvesCategories);
  },
  getStore: (id:number) => {
    return S_Datas._getFromList(id,S_Datas.stores);
  },
  getList: (id:number) => {
    return S_Datas._getFromList(id,S_Datas.lists);
  },


  listMove(from,to,cb:any){
    if(!S_Datas.lists || S_Datas.lists.length<=1){
      if(cb) cb();
      return;
    }
    console.log('listMove',S_Datas.lists,from.oldIndex,from.newIndex);
    S_Datas.lists = arrayMove(S_Datas.lists,from.oldIndex,from.newIndex);
    console.log('listMove',S_Datas.lists);
    S_Datas.store(cb);
    if(cb) cb();
  },

  
  deleteAllStorage(cb:any) {
    console.log('deleteAllStorage...');
    // window.localStorage.removeItem(S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_ITEMS);
    // window.localStorage.removeItem(S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_SHELVES);
    window.localStorage.removeItem(S_App.STORAGE_KEY_PREFIX + '_' + S_Datas.STORAGE_KEY_LISTS);
    S_Datas.fetch(cb);
  },

  newItem(cb:any) {
    if(!S_Datas.items) S_Datas.items = [];
    // let newID = S_Datas.items.length>0 ? S_Datas.items[S_Datas.items.length - 1].id + 1 : 1;
    let newID = (new Date()).getTime();
    let newOne = {
      id: newID,
      code:'empty',
      i18n:{'fr':'Nouveau produit'},
      shelvesID:[],
      pictures:[],
      tagsID:[1],
      keywordsID:[],
      barcode:''
    };
    console.log('Add...',newOne);
    console.log(S_Datas.items);
    S_Datas.items.push(newOne);
    S_Datas.store(cb);
  },
  updateItem(value,attributeName,item,cb:any){
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Datas.items.length; i++){
      if(S_Datas.items[i].id === item.id){
        foundData = S_Datas.items[i];
        foundData[attributeName] = value;
        break;
      }
    }
    console.log('Update...',foundData);
    console.log(S_Datas.items);
    S_Datas.store(cb);
    if(cb) cb(foundData);
  },
  deleteItem(item,cb:any){
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    let foundDataIndex = -1;
    for(var i = 0; i < S_Datas.items.length; i++){
      if(S_Datas.items[i].id === item.id){
        foundDataIndex = i;
        break;
      }
    }
    if(foundDataIndex !== -1){
      S_Datas.items.splice(i,1);
    }
    console.log('Delete...',foundDataIndex);
    console.log(S_Datas.items);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeItemName(item,newName,cb:any) {
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getItem(item.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }
    // console.log('changeItemName',itemFound);

    itemFound.i18n.fr = newName;
    itemFound.code = newName.toLowerCase().replace(' ','');
    console.log('changeItemName',itemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeItemShelves(item,newShelvesID,cb:any) {
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getItem(item.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }
    console.log('changeItemShelves',itemFound,newShelvesID);

    itemFound.shelvesID = newShelvesID;
    console.log('Change...',itemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeItemTags(item,newTagsID,cb:any) {
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getItem(item.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }
    console.log('changeItemTags',itemFound,newTagsID);

    itemFound.tagsID = newTagsID;
    console.log('Change...',itemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeItemBarCode(item,newBarcode,cb:any) {
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getItem(item.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }
    // console.log('changeItemBarCode',itemFound);

    itemFound.barcode = newBarcode;
    console.log('changeItemBarCode...',itemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeItemPrice(item,newPrice,cb:any) {
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getItem(item.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }
    // console.log('changeItemName',itemFound);

    itemFound.price = newPrice;
    console.log('changeItemPrice',itemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeItemPriceUnity(item,newPriceUnity,cb:any) {
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getItem(item.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }
    // console.log('changeItemName',itemFound);

    itemFound.priceUnity = newPriceUnity;
    console.log('changeItemPriceUnity',itemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeItemMain(item,newMainID,cb:any) {
    if(!S_Datas.items || S_Datas.items.length===0 || !item){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getItem(item.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }

    itemFound.mainID = newMainID;
    console.log('changeItemMain',itemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },

  addItemToList(item,list,cb:any){
    if(!S_Datas.lists || S_Datas.lists.length===0 || !item){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    // let newID = listFound.listItems.length>0 ? listFound.listItems[listFound.listItems.length - 1].id + 1 : 1;
    let newID = (new Date()).getTime();
    let newOne = {
      'id': newID,
      'itemID': item.id,
      'themesID': [],         
      'notes': ''
    };
    console.log('Add...',newOne);
    listFound.listItems.push(newOne);
    S_Datas.store(() => cb(newOne));
  },
  deleteListItem(list,listItem,cb:any){
    if(!S_Datas.lists || S_Datas.lists.length===0 || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    if(listItem.itemID!==undefined){
      let foundListItemIndex = -1;
      for(var i = 0; i < listFound.listItems.length; i++){
        if(listFound.listItems[i].id === listItem.id){
          foundListItemIndex = i;
          break;
        }
      }
      listFound.listItems.splice(i,1);
      console.log('Delete...',foundListItemIndex);
    }else{
      let foundListItemIndex = -1;
      for(var i = 0; i < listFound.listItemsTemporary.length; i++){
        if(listFound.listItemsTemporary[i].id === listItem.id){
          foundListItemIndex = i;
          break;
        }
      }
      listFound.listItemsTemporary.splice(i,1);
      console.log('Delete...',foundListItemIndex);
    }
    
    console.log(S_Datas.lists);
    S_Datas.store(cb);
    if(cb) cb();
  },
  isItemInLists(item) {
    if(!S_Datas.lists || S_Datas.lists.length===0)
      return false;
    if(!item)
      return false;

    for(var i = 0; i < S_Datas.lists.length; i++){
      let list = S_Datas.lists[i];
      let listItems = list ? list.listItems : [];
      for(var j = 0; j < listItems.length; j++){
        let listItem = listItems[j];
        if(listItem && listItem.itemID === item.id){
          console.log('Item',item,'is in a list');
          return true;
        }
      }
    }
    console.log('Item',item,'is not in a list');
    return false;
  },
  newItemTemporaryToList(list,cb:any){
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    if(!listFound.listItemsTemporary) listFound.listItemsTemporary = [];
    // let newID = listFound.listItemsTemporary.length>0 ? listFound.listItemsTemporary[listFound.listItemsTemporary.length - 1].id + 1 : 1;
    let newID = (new Date()).getTime();
    let newOne = {
      id: newID,
      themesID: [],         
      notes: '',
      code:'empty',
      i18n:{'fr':'Nouveau produit'},
      shelvesID:[],
      pictures:[],
      tagsID:[1],
      keywordsID:[],
      barcode:''
    };
    console.log('Add...',newOne);
    listFound.listItemsTemporary.push(newOne);
    S_Datas.store(cb);
  },

  _formatListItem(listItem) {
    let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
    let itemName = item.i18n.fr;
    let itemImportant = listItem.important;
    let itemNotes = listItem.notes && listItem.notes!=='' ? ' : ' + listItem.notes : '';
    let itemShelvesID = item.shelvesID;
    let itemTagsID = item.tagsID;
    let itemThemesID = listItem.themesID;
    let itemPrice = item.price;
    let itemPriceUnity = item.priceUnity && item.priceUnity!=='' ? ' ' + item.priceUnity : '';
    let itemQuantity = listItem.quantity && listItem.quantity!=='' ? ' (qté ' + listItem.quantity + itemPriceUnity + ')' : '';
    return (itemImportant ? '(!) ' : '') + itemName + itemNotes + itemQuantity;
  },
  _formatShelf(shelf) {
    if(!shelf)
      return '';
    return '\n\nRayon "' + shelf.i18n.fr + '":';
  },
  _formatStore(store) {
    if(!store)
      return 'A "magasin indéterminé"';
    return 'A "' + store.i18n.fr + '"';
  },
  _formatListByDate(list) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list)
      return '';
    let listFound = S_Datas.getList(list.id);
    if(!listFound)
      return '';

    // by date
    let contents = '';
    for(var i = 0; i < listFound.listItems.length; i++){
      let listItem = listFound.listItems[i];
      contents += '\n' + S_Datas._formatListItem(listItem);
    }
    for(var i = 0; i < listFound.listItemsTemporary.length; i++){
      let listItem = listFound.listItemsTemporary[i];
      contents += '\n' + S_Datas._formatListItem(listItem);
    }
    console.log('formatList',contents);
    return contents;
  },
  _formatListByShelves(list) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list)
      return '';
    let listFound = S_Datas.getList(list.id);
    if(!listFound)
      return '';

    // par store rayon
    let contents = '';
    for(var m = 0; m < S_Datas.stores.length; m++){
      let store = S_Datas.stores[m];
      let storeName = store.i18n.fr;

      let storeContents = '';

      for(var i = 0; i < S_Datas.shelves.length; i++){
        let shelf = S_Datas.shelves[i];
        let shelfName = shelf.i18n.fr;
        let shelfStoreID = shelf.storeID;
        if(shelfStoreID===undefined || shelfStoreID!==store.id)
          continue;

        let shelfContents = '';
        for(var j = 0; j < listFound.listItems.length; j++){
          let listItem = listFound.listItems[j];
          let item = S_Datas.getItem(listItem.itemID);
          let itemShelvesID = item.shelvesID;
          // console.log(listItem,item);
          if(itemShelvesID.indexOf(shelf.id)!==-1)
            shelfContents += '\n' + S_Datas._formatListItem(listItem);
        }
        for(var j = 0; j < listFound.listItemsTemporary.length; j++){
          let listItem = listFound.listItemsTemporary[j];
          let item = listItem;
          let itemShelvesID = item.shelvesID;
          // console.log(listItem,item);
          if(itemShelvesID.indexOf(shelf.id)!==-1)
            shelfContents += '\n' + S_Datas._formatListItem(listItem);
        }

        if(shelfContents!==''){
          storeContents += S_Datas._formatShelf(shelf);
          storeContents += shelfContents;
        }
      }
      if(storeContents!==''){
        contents += (contents!=='' ? '\n\n' : '') + S_Datas._formatStore(store);
        contents += storeContents;
      }
    }

    // rayons sans store
    let noStoreContents = '';
    for(var i = 0; i < S_Datas.shelves.length; i++){
      let shelf = S_Datas.shelves[i];
      let shelfName = shelf.i18n.fr;
      let shelfStoreID = shelf.storeID;
      if(shelfStoreID!==undefined && S_Datas.getStore(shelfStoreID)!==undefined)
        continue;

      let shelfContents = '';
      for(var j = 0; j < listFound.listItems.length; j++){
        let listItem = listFound.listItems[j];
        let item = S_Datas.getItem(listItem.itemID);
        let itemShelvesID = item.shelvesID;
        // console.log(listItem,item);
        if(itemShelvesID.indexOf(shelf.id)!==-1)
          shelfContents += '\n' + S_Datas._formatListItem(listItem);
      }
      for(var j = 0; j < listFound.listItemsTemporary.length; j++){
        let listItem = listFound.listItemsTemporary[j];
        let item = listItem;
        let itemShelvesID = item.shelvesID;
        // console.log(listItem,item);
        if(itemShelvesID.indexOf(shelf.id)!==-1)
          shelfContents += '\n' + S_Datas._formatListItem(listItem);
      }

      if(shelfContents!==''){
        noStoreContents += S_Datas._formatShelf(shelf);
        noStoreContents += shelfContents;
      }
    }
    if(noStoreContents!==''){
      contents += (contents!=='' ? '\n\n' : '') + S_Datas._formatStore(undefined);
      contents += noStoreContents;
    }

    // sans rayon
    let noShelfContents = '';
    for(var j = 0; j < listFound.listItems.length; j++){
      let listItem = listFound.listItems[j];
      let item = S_Datas.getItem(listItem.itemID);
      let itemShelvesID = item.shelvesID;
      // console.log('no shelf?',itemShelvesID);
      if(!itemShelvesID || itemShelvesID.length===0)
        noShelfContents += '\n' + S_Datas._formatListItem(listItem);
    }
    for(var j = 0; j < listFound.listItemsTemporary.length; j++){
      let listItem = listFound.listItemsTemporary[j];
      let item = listItem;
      let itemShelvesID = item.shelvesID;
      // console.log('no shelf?',itemShelvesID);
      if(!itemShelvesID || itemShelvesID.length===0)
        noShelfContents += '\n' + S_Datas._formatListItem(listItem);
    }
    // console.log('no shelf?',noShelfContents);
    if(noShelfContents!==''){
      contents += (contents!=='' ? '\n\n' : '') + S_Datas._formatShelf(undefined);
      contents += noShelfContents;
    }

    console.log('formatList :',contents);
    return contents;
  },
  formatList(list,mode) {
    if(!mode || mode==='default')
      return S_Datas._formatListByDate(list);

    if(mode==='shelves'){
      if(!S_Datas.shelves || S_Datas.shelves.length===0)
        return S_Datas._formatListByDate(list);
      return S_Datas._formatListByShelves(list);
    }

    return '';
  },
  newList(cb:any) {
    if(!S_Datas.lists) S_Datas.lists = [];
    // let newID = S_Datas.lists.length ? S_Datas.lists[S_Datas.lists.length - 1].id + 1 : 1;
    let newID = (new Date()).getTime();
    let newOne = {
      'id':newID,
      'name':'Nouvelle liste',
      'themes':[],
      'listItems':[],
      'listItemsTemporary':[]
    };
    console.log('Add...',newOne);
    console.log(S_Datas.lists);
    S_Datas.lists.push(newOne);
    S_Datas.store(cb);
  },
  deleteList(list,cb:any){
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list){
      if(cb) cb();
      return;
    }
    let foundDataIndex = -1;
    for(var i = 0; i < S_Datas.lists.length; i++){
      if(S_Datas.lists[i].id === list.id){
        foundDataIndex = i;
        break;
      }
    }
    if(foundDataIndex !== -1){
      S_Datas.lists.splice(i,1);
    }
    console.log('Delete...',foundDataIndex);
    console.log(S_Datas.lists);
    S_Datas.store(cb);
    if(cb) cb();
  },
  emptyList(list,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    listFound.listItems.length = 0;
    listFound.listItemsTemporary.length = 0;
    console.log('Empty...',listFound);
    console.log(S_Datas.lists);
    S_Datas.store(cb);
    if(cb) cb();
  },
  renameList(list,newName,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    listFound.name = newName;
    console.log('Rename...',listFound);
    console.log(S_Datas.lists);
    S_Datas.store(cb);
    if(cb) cb();
  },
  newTheme(list,cb:any){
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    // let newID = listFound.themes.length ? listFound.themes[listFound.themes.length - 1].id + 1 : 1;
    let newID = (new Date()).getTime();
    let newOne = {
      'id':newID,
      'name':'Nouveau theme'
    };
    console.log('Add...',newOne);
    listFound.themes.push(newOne);
    S_Datas.store(cb);
  },
  renameListTheme(list, theme, newName, cb:any){
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !theme){
      if(cb) cb(theme);
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb(theme);
      return;
    }

    let themeFound = S_Datas._getFromList(theme.id,listFound.themes);
    if(!themeFound){
      if(cb) cb(theme);
      return;
    }

    themeFound.name = newName;
    console.log('Rename...',themeFound);
    S_Datas.store(() => cb(themeFound));
  },
  deleteListTheme(list,theme,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !theme){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    let foundThemeIndex = -1;
    for(var i = 0; i < listFound.themes.length; i++){
      if(listFound.themes[i].id === theme.id){
        foundThemeIndex = i;
        break;
      }
    }
    if(foundThemeIndex === -1){
      if(cb) cb();
      return;
    }

    // NB on ne desassocie pas les items... on devrait.

    listFound.themes.splice(i,1);
    console.log('Delete...',foundThemeIndex);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeListItemNotes(list,listItem,newNotes,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    let listitemFound = undefined;
    if(listItem.itemID!==undefined){
      listitemFound = S_Datas._getFromList(listItem.id,listFound.listItems);
    }else{
      listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    }
    if(!listitemFound){
      if(cb) cb();
      return;
    }

    listitemFound.notes = newNotes;
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeListItemThemes(list,listItem,newThemesID,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    let listitemFound = undefined;
    if(listItem.itemID!==undefined){
      listitemFound = S_Datas._getFromList(listItem.id,listFound.listItems);
    }else{
      listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    }
    if(!listitemFound){
      if(cb) cb();
      return;
    }
    if(!listitemFound){
      if(cb) cb();
      return;
    }

    // console.log('changeListItemThemes',newThemesID);

    listitemFound.themesID = newThemesID;
    console.log('Change...',listitemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  toggleListItemImportant(list,listItem,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }
    console.log('toggleListItemImportant',list,listItem,listFound);

    let listitemFound = undefined;
    if(listItem.itemID!==undefined){
      listitemFound = S_Datas._getFromList(listItem.id,listFound.listItems);
    }else{
      listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    }
    if(!listitemFound){
      if(cb) cb();
      return;
    }
    console.log('toggleListItemImportant',list,listItem,listitemFound);

    listitemFound.important = listitemFound.important!==undefined ? !listitemFound.important : true;
    console.log('Change...',listitemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeListItemQuantity(list,listItem,newQuantity,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }

    let listitemFound = undefined;
    if(listItem.itemID!==undefined){
      listitemFound = S_Datas._getFromList(listItem.id,listFound.listItems);
    }else{
      listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    }
    if(!listitemFound){
      if(cb) cb();
      return;
    }

    listitemFound.quantity = newQuantity;
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeListItemTemporaryName(list,listItem,newName,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }
    console.log('changeListItemTemporaryName',list,listItem,listFound);

    let listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    if(!listitemFound){
      if(cb) cb();
      return;
    }
    console.log('changeListItemTemporaryName',listitemFound);

    listitemFound.i18n.fr = newName;
    listitemFound.code = newName.toLowerCase().replace(' ','');
    console.log('Change...',listitemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeListItemTemporaryShelves(list,listItem,newShelvesID,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }
    console.log('changeListItemTemporaryShelves',list,listItem,listFound);

    let listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    if(!listitemFound){
      if(cb) cb();
      return;
    }
    console.log('changeListItemTemporaryShelves',listitemFound);

    listitemFound.shelvesID = newShelvesID;
    console.log('Change...',listitemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeListItemTemporaryTags(list,listItem,newTagsID,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }
    console.log('changeListItemTemporaryTags',list,listItem,listFound);

    let listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    if(!listitemFound){
      if(cb) cb();
      return;
    }
    console.log('changeListItemTemporaryTags',listitemFound);

    listitemFound.tagsID = newTagsID;
    console.log('Change...',listitemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeListItemTemporaryPrice(list,listItem,newPrice,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }
    console.log('changeListItemTemporaryPrice',list,listItem,listFound);

    let listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    if(!listitemFound){
      if(cb) cb();
      return;
    }

    listitemFound.price = newPrice;
    console.log('changeListItemTemporaryPrice',listitemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeListItemTemporaryPriceUnity(list,listItem,newPriceUnity,cb:any) {
    if(!S_Datas.lists || S_Datas.lists.length===0 || !list || !listItem){
      if(cb) cb();
      return;
    }
    let listFound = S_Datas.getList(list.id);
    if(!listFound){
      if(cb) cb();
      return;
    }
    console.log('changeListItemTemporaryPriceUnity',list,listItem,listFound);

    let listitemFound = S_Datas._getFromList(listItem.id,listFound.listItemsTemporary);
    if(!listitemFound){
      if(cb) cb();
      return;
    }

    listitemFound.priceUnity = newPriceUnity;
    console.log('changeListItemTemporaryPriceUnity',listitemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },

  fetchOpenFoodFact(barcode,cb:any,cbError:any) {
    if(S_App.isLogDebug) console.log('-- Datas.tsx -- fetchOpenFoodFact(',barcode,')...');
    fetch('https://world.openfoodfacts.org/api/v0/product/'+barcode+'.json')
      .then(res => res.json())
      .then(
        (result) => {
          if(S_App.isLogDebug) console.log('-- Datas.tsx -- fetchOpenFoodFact OK',result);
          if(cb)
            cb(result);
        },
        (error) => {
          if(S_App.isLogDebug) console.error('-- Datas.tsx -- fetchOpenFoodFact error',error);
          if(cbError)
            cbError(error);
        }
      )
  },
  applyOpenFoodFact(item,product,cb:any) {
    if(!S_Datas.items || S_Datas.items.length===0 || !item || !product){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getItem(item.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }
    // console.log('applyOpenFoodFact',itemFound);

    let image_url = product.image_front_small_url;
    let quantity = product.quantity;
    let name = product.product_name;
    let gname = product.generic_name
    let brands = product.brands;
    console.log('applyOpenFoodFact',name,brands,quantity,image_url);

    itemFound.i18n.fr = name + ' [' + brands + '] ' + quantity;
    if(itemFound.pictures===undefined) itemFound.pictures = [];
    itemFound.pictures.push(image_url);
    itemFound.tagsID = [2];  // specifique
    console.log('applyOpenFoodFact...',itemFound);
    S_Datas.store(cb);
    if(cb) cb();
  },


  // STORES ---------------
  newStore(cb:any) {
    if(!S_Datas.stores) S_Datas.stores = [];
    // let newID = S_Datas.stores.length>0 ? S_Datas.stores[S_Datas.stores.length - 1].id + 1 : 1;
    let newID = (new Date()).getTime();
    let newOne = {
      id: newID,
      i18n:{'fr':'Nouveau magasin'}
    };
    console.log('Add...',newOne);
    console.log(S_Datas.stores);
    S_Datas.stores.push(newOne);
    S_Datas.store(cb);
  },
  deleteStore(store,cb:any){
    if(!S_Datas.stores || S_Datas.stores.length===0 || !store){
      if(cb) cb();
      return;
    }
    let foundDataIndex = -1;
    for(var i = 0; i < S_Datas.stores.length; i++){
      if(S_Datas.stores[i].id === store.id){
        foundDataIndex = i;
        break;
      }
    }
    if(foundDataIndex !== -1){
      S_Datas.stores.splice(i,1);
    }
    console.log('Delete...',foundDataIndex);
    console.log(S_Datas.stores);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeStoreName(store,newName,cb:any) {
    if(!S_Datas.stores || S_Datas.stores.length===0 || !store){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getStore(store.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }

    itemFound.i18n.fr = newName;
    S_Datas.store(cb);
    if(cb) cb();
  },
  storeMove(from,to,cb:any){
    if(!S_Datas.stores || S_Datas.stores.length<=1){
      if(cb) cb();
      return;
    }
    console.log('storeMove',S_Datas.stores,from.oldIndex,from.newIndex);
    S_Datas.stores = arrayMove(S_Datas.stores,from.oldIndex,from.newIndex);
    console.log('storeMove',S_Datas.stores);
    S_Datas.store(cb);
    if(cb) cb();
  },

  // SHELVES ---------------
  newShelf(cb:any) {
    if(!S_Datas.shelves) S_Datas.shelves = [];
    // let newID = S_Datas.shelves.length>0 ? S_Datas.shelves[S_Datas.shelves.length - 1].id + 1 : 1;
    let newID = (new Date()).getTime();
    let newOne = {
      id: newID,
      i18n:{'fr':'Nouveau rayon'}
    };
    console.log('Add...',newOne);
    console.log(S_Datas.shelves);
    S_Datas.shelves.push(newOne);
    S_Datas.store(cb);
  },
  newShelfInStore(store,cb:any) {
    if(!S_Datas.shelves) S_Datas.shelves = [];
    // let newID = S_Datas.shelves.length>0 ? S_Datas.shelves[S_Datas.shelves.length - 1].id + 1 : 1;
    let newID = (new Date()).getTime();
    let newOne = {
      id: newID,
      i18n:{'fr':'Nouveau rayon'},
      storeID:store.id
    };
    console.log('Add...',newOne);
    console.log(S_Datas.shelves);
    S_Datas.shelves.push(newOne);
    S_Datas.store(cb);
  },
  deleteShelf(shelf,cb:any){
    if(!S_Datas.shelves || S_Datas.shelves.length===0 || !shelf){
      if(cb) cb();
      return;
    }
    let foundDataIndex = -1;
    for(var i = 0; i < S_Datas.shelves.length; i++){
      if(S_Datas.shelves[i].id === shelf.id){
        foundDataIndex = i;
        break;
      }
    }
    if(foundDataIndex !== -1){
      S_Datas.shelves.splice(i,1);
    }
    console.log('Delete...',foundDataIndex);
    console.log(S_Datas.shelves);
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeShelfName(shelf,newName,cb:any) {
    if(!S_Datas.shelves || S_Datas.shelves.length===0 || !shelf){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getShelf(shelf.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }

    itemFound.i18n.fr = newName;
    S_Datas.store(cb);
    if(cb) cb();
  },
  changeShelfStore(shelf,newStoreID,cb:any) {
    if(!S_Datas.shelves || S_Datas.shelves.length===0 || !shelf){
      if(cb) cb();
      return;
    }
    let itemFound = S_Datas.getShelf(shelf.id);
    if(!itemFound){
      if(cb) cb();
      return;
    }

    itemFound.storeID = newStoreID;
    S_Datas.store(cb);
    if(cb) cb();
  },
  shelfMove(from,to,cb:any){
    if(!S_Datas.shelves || S_Datas.shelves.length<=1){
      if(cb) cb();
      return;
    }
    console.log('shelfMove',S_Datas.shelves,from.oldIndex,from.newIndex);
    S_Datas.shelves = arrayMove(S_Datas.shelves,from.oldIndex,from.newIndex);
    console.log('shelfMove',S_Datas.shelves);
    S_Datas.store(cb);
    if(cb) cb();
  }

};
export default S_Datas;