import React from 'react';
import logo from './logo.svg';
import './App.scss';
// import pkg from '../package.json';


import Quagga from 'quagga'; // ES6

// import S_App     from   './services/App';
import S_Datas   from   './services/Datas';

import PageLists     from   './pages/page-lists';
import PageList      from   './pages/page-list';
import PageItems     from   './pages/page-items';
import PageStores    from   './pages/page-stores';
import PageShelves    from   './pages/page-shelves';

// declare navigator:any;



class App extends React.Component<any,any> {

  static propTypes = {};
  homeMenuAnchorRef:any = undefined;
  moreMenuAnchorRef:any = undefined;

  constructor(props: any) {
    super(props);
    this.state = {
      items: [],
      keywords: [],
      tags: [],
      shelves: [],
      shelvesCategories: [],
      stores: [],
      lists: [],

      view: 'default',  // default (lists) | list | items | stores | shelves
      viewItem: undefined,

      orderListItemsBy: 'names', // names | shelves | themes | price
      editListItems: false,

      orderItemsBy: 'shelves', // names | shelves

      sortShelves: undefined,  // activation des boutons de tri
      sortLists: undefined,

      openHomeMenu: false,
      displayHomeFabs: false,
      openMoreMenu: false,
      openRenameListModal: false,
      openRenameThemeModal: false,
      renameTheme: undefined,      // objet theme a renommer

      openListItemModal: false,
      detailsListItem: undefined,    // objet listItem a modifier

      openItemModal: false,
      detailsItem: undefined,    // objet item a modifier

      openSelectItem: false,

      openStoreModal: false,
      detailsStore: undefined,

      openShelfModal: false,
      detailsShelf: undefined,

      scannerEnabled: true,
      scannerInitialized: false,
      scannerRunning: false,
      alertScan: undefined,

      displayListFabs: false

    };
    this.viewDefault = this.viewDefault.bind(this);
    this.viewList = this.viewList.bind(this);
    this.viewItems = this.viewItems.bind(this);
    this.viewStores = this.viewStores.bind(this);
    this.viewShelves = this.viewShelves.bind(this);

    this.changeOrderListItems = this.changeOrderListItems.bind(this);
    this.changeOrderItems = this.changeOrderItems.bind(this);
    this.shareCurrentList = this.shareCurrentList.bind(this);
    this.toggleEditList = this.toggleEditList.bind(this);
    this.deleteListItem = this.deleteListItem.bind(this);
    this.addItemToList = this.addItemToList.bind(this);
    this.addList = this.addList.bind(this);
    this.closeHomeMenu = this.closeHomeMenu.bind(this);
    this.toggleHomeMenu = this.toggleHomeMenu.bind(this);
    this.closeMoreMenu = this.closeMoreMenu.bind(this);
    this.toggleMoreMenu = this.toggleMoreMenu.bind(this);
    this.deleteCurrentList = this.deleteCurrentList.bind(this);
    this.openRenameCurrentList = this.openRenameCurrentList.bind(this);
    this.closeRenameListModal = this.closeRenameListModal.bind(this);
    this.renameCurrentList = this.renameCurrentList.bind(this);
    this.addThemeToList = this.addThemeToList.bind(this);
    this.openRenameTheme = this.openRenameTheme.bind(this);
    this.closeRenameTheme = this.closeRenameTheme.bind(this);
    this.renameCurrentListTheme = this.renameCurrentListTheme.bind(this);
    this.deleteCurrentListTheme = this.deleteCurrentListTheme.bind(this);
    this.openDetailsListItem = this.openDetailsListItem.bind(this);
    this.closeDetailsListItemModal = this.closeDetailsListItemModal.bind(this);
    this.changeListItemNotes = this.changeListItemNotes.bind(this);
    this.changeListItemThemes = this.changeListItemThemes.bind(this);
    this.changeListItemImportant = this.changeListItemImportant.bind(this);
    this.deleteAllStorage = this.deleteAllStorage.bind(this);
    this.openDetailsItem = this.openDetailsItem.bind(this);
    this.closeDetailsItemModal = this.closeDetailsItemModal.bind(this);
    this.changeItemName = this.changeItemName.bind(this);
    this.changeItemShelves = this.changeItemShelves.bind(this);
    this.changeItemTags = this.changeItemTags.bind(this);
    this.toggleSelectItemDrawer = this.toggleSelectItemDrawer.bind(this);
    this.addItem = this.addItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.addItemTemporary = this.addItemTemporary.bind(this);
    this.changeListItemTemporaryName = this.changeListItemTemporaryName.bind(this);
    this.changeListItemTemporaryShelves = this.changeListItemTemporaryShelves.bind(this);
    this.changeListItemTemporaryTags = this.changeListItemTemporaryTags.bind(this);
    this.changeListItemTemporaryPrice = this.changeListItemTemporaryPrice.bind(this);
    this.changeListItemTemporaryPriceUnity = this.changeListItemTemporaryPriceUnity.bind(this);
    this.openScan = this.openScan.bind(this);
    this.initScanner = this.initScanner.bind(this);
    this.changeItemBarcode = this.changeItemBarcode.bind(this);
    this.askOpenFoodFact = this.askOpenFoodFact.bind(this);
    this.initScanner = this.initScanner.bind(this);
    this.openScan = this.openScan.bind(this);
    this.askOpenFoodFact = this.askOpenFoodFact.bind(this);
    this.stopScan = this.stopScan.bind(this);
    this.closeAlertScan = this.closeAlertScan.bind(this);
    this.changeItemPrice = this.changeItemPrice.bind(this);
    this.changeItemPriceUnity = this.changeItemPriceUnity.bind(this);
    this.changeListItemQuantity = this.changeListItemQuantity.bind(this);
    this.changeItemMain = this.changeItemMain.bind(this);
    this.toggleExpandItem = this.toggleExpandItem.bind(this);
    this.emptyCurrentList = this.emptyCurrentList.bind(this);

    // vue lists
    this.toggleFabs = this.toggleFabs.bind(this);
    this.toggleSortLists = this.toggleSortLists.bind(this);
    this.listMove = this.listMove.bind(this);


    // vue stores
    this.addStore = this.addStore.bind(this);
    this.deleteStore = this.deleteStore.bind(this);
    this.openStoreModal = this.openStoreModal.bind(this);
    this.closeStoreModal = this.closeStoreModal.bind(this);
    this.changeStoreName = this.changeStoreName.bind(this);
    this.storeMove = this.storeMove.bind(this);

    // vue shelves
    this.addShelf = this.addShelf.bind(this);
    this.addShelfInStore = this.addShelfInStore.bind(this);
    this.deleteShelf = this.deleteShelf.bind(this);
    this.openShelfModal = this.openShelfModal.bind(this);
    this.closeShelfModal = this.closeShelfModal.bind(this);
    this.changeShelfName = this.changeShelfName.bind(this);
    this.changeShelfStore = this.changeShelfStore.bind(this);
    this.shelfMove = this.shelfMove.bind(this);
    this.toggleSortShelves = this.toggleSortShelves.bind(this);

    // divers

    this.homeMenuAnchorRef = React.createRef();
    this.moreMenuAnchorRef = React.createRef();

    this.onToggleListFabs = this.onToggleListFabs.bind(this);
  }

  componentDidMount() {
    S_Datas.fetch(() => {
      this.setState({
        items: S_Datas.items,
        keywords: S_Datas.keywords,
        tags: S_Datas.tags,
        shelves: S_Datas.shelves,
        shelvesCategories: S_Datas.shelvesCategories,
        stores: S_Datas.stores,
        lists: S_Datas.lists
      });
    });
    // init lecteur de code barre
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
      this.setState({
        scannerEnabled: true
      });
    }
  }
  componentWillUnmount() {
    if(this.state.scannerInitialized){
      this.stopScan();
    }
  }



  viewDefault(e) {
    this.setState({
      view: 'default',
      viewItem: undefined,
      openHomeMenu: false,
      openMoreMenu: false
    });
  }
  viewList(e,list) {
    this.setState({
      view: 'list',
      viewItem: list,
      orderListItemsBy: list && (list.listItems.length+list.listItemsTemporary.length)>15 ? 'shelves' : 'date'
    });
  }
  viewItems(e) {
    this.setState((state) => ({
      view: 'items',
      viewItem: undefined,
      orderItemsBy: state.items.length>15 ? 'shelves' : 'names'
    }));
  }
  viewStores(e) {
    this.setState((state) => ({
      view: 'stores',
      viewItem: undefined
    }));
  }
  viewShelves(e) {
    this.setState((state) => ({
      view: 'shelves',
      viewItem: undefined
    }));
  }


  changeOrderListItems(event: React.ChangeEvent<{}>, newValueIndex: number) {
    let mode = 'date';
    if(newValueIndex === 1) mode = 'names';
    if(newValueIndex === 2) mode = 'shelves';
    if(newValueIndex === 3) mode = 'themes';
    if(newValueIndex === 4) mode = 'price';
    this.setState({
      orderListItemsBy: mode
    });
  }
  changeOrderItems(event: React.ChangeEvent<{}>, newValueIndex: number) {
    let mode = 'names';
    if(newValueIndex === 1) mode = 'shelves';
    this.setState({
      orderItemsBy: mode
    });
  }

  shareCurrentList(e) {
    let contents = S_Datas.formatList(this.state.viewItem,'shelves');
    console.log('Share list',this.state.viewItem.name,contents);
    let navigatorSystem: any;
    navigatorSystem = window.navigator;
    if(navigatorSystem && navigatorSystem.share) {
      navigatorSystem.share({
        title: this.state.viewItem.name,
        text: contents
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    }else{
      //
    }
  }
  listMove(from,to) {
    S_Datas.listMove(from,to,() => {
      this.setState((state) => {
        return {
          lists: S_Datas.lists
        };
      });
    });
  }

  closeHomeMenu(e) {
    if(this.homeMenuAnchorRef.current && this.homeMenuAnchorRef.current.contains(e.target as HTMLElement))
      return;
    this.setState((state) => ({
      openHomeMenu: false
    }));
  }
  toggleHomeMenu(e) {
    this.setState((state) => ({
      openHomeMenu: !state.openHomeMenu
    }));
  }
  closeMoreMenu(e) {
    if(this.moreMenuAnchorRef.current && this.moreMenuAnchorRef.current.contains(e.target as HTMLElement))
      return;
    this.setState((state) => ({
      openMoreMenu: false
    }));
  }
  toggleMoreMenu(e) {
    this.setState((state) => ({
      openMoreMenu: !state.openMoreMenu
    }));
  }

  toggleEditList(e) {
    this.setState((state) => ({
      editListItems: !state.editListItems
    }));
  }
  addList(e) {
    S_Datas.newList(() => {
      this.setState({
        lists: S_Datas.lists
      },() => {
        this.viewList(undefined,S_Datas.lists[S_Datas.lists.length - 1]);
        this.openRenameCurrentList(undefined);
      });
    });
  }

  toggleSortLists(cb) {
    this.setState((state) => {
      console.log('toggleSortLists : ',state.sortLists);
      let newOne = state.sortLists === undefined || state.sortLists === false ? true : false;
      return ({
        sortLists: newOne
      });
    },() => {
      if(cb)
        cb();
    });
  }


  deleteListItem(listItem) {
    if(!listItem)
      return;
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    S_Datas.deleteListItem(this.state.viewItem,listItem,() => {
      this.setState({
        lists: S_Datas.lists
      });
    });
  }
  addItemToList(item) {
    if(!item)
      return;
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    S_Datas.addItemToList(item,this.state.viewItem,(newOne) => {
      this.setState({
        lists: S_Datas.lists,
        openListItemModal: true,
        detailsListItem: newOne
      });
    });
  }
  deleteCurrentList(e) {
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    S_Datas.deleteList(this.state.viewItem,() => {
      this.setState({
        lists: S_Datas.lists,
        openMoreMenu: false,
        view: 'default',
        viewItem: undefined
      });
    });
  }
  openRenameCurrentList(e) {
    this.setState({
      openMoreMenu: false,
      openRenameListModal: true
    });
  }
  closeRenameListModal(e) {
    this.setState({
      openRenameListModal: false
    });
  }
  emptyCurrentList(e){
    if(this.state.view !== 'list')
      return;
    S_Datas.emptyList(this.state.viewItem,() => {
      this.setState({
        lists: S_Datas.lists,
        viewItem: S_Datas.getList(this.state.viewItem.id)
      });
    });
  }
  renameCurrentList(e) {
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    let value = e.target.value;
    S_Datas.renameList(this.state.viewItem,value,() => {
      this.setState({
        lists: S_Datas.lists,
        viewItem: S_Datas.getList(this.state.viewItem.id)
      });
    });
  }
  addThemeToList(e) {
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    S_Datas.newTheme(this.state.viewItem,() => {
      this.setState({
        lists: S_Datas.lists
      });
    });
  }
  openRenameTheme(e,theme) {
    if(!theme)
      return;
    this.setState({
      openRenameThemeModal: true,
      renameTheme: theme
    });
  }
  closeRenameTheme(e) {
    this.setState({
      openRenameThemeModal: false
    });
  }
  renameCurrentListTheme(e) {
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    let value = e.target.value;
    console.log('Rename theme',this.state.renameTheme,value);
    S_Datas.renameListTheme(this.state.viewItem,this.state.renameTheme,value,(newTheme) => {
      this.setState({
        lists: S_Datas.lists,
        renameTheme: newTheme
      });
    });
  }
  deleteCurrentListTheme(e,theme) {
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    S_Datas.deleteListTheme(this.state.viewItem,theme,() => {
      this.setState({
        lists: S_Datas.lists
      });
    });
  }

  openDetailsListItem(e,listItem) {
    this.setState({
      openListItemModal: true,
      detailsListItem: listItem
    });
  }
  closeDetailsListItemModal(e) {
    this.setState({
      openListItemModal: false,
      detailsListItem: undefined
    });
  }
  changeListItemNotes(e) {
    console.log('changeListItemNotes');
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    let value = e.target.value;
    console.log('changeListItemNotes',value);
    S_Datas.changeListItemNotes(this.state.viewItem,this.state.detailsListItem,value,() => {
      this.setState((state) => {
        let list = state.detailsListItem.itemID!==undefined ? state.viewItem.listItems : state.viewItem.listItemsTemporary;
        return {
          lists: S_Datas.lists,
          detailsListItem: S_Datas._getFromList(state.detailsListItem.id,list)
        };
      });
    });
  }
  changeListItemThemes(e) {
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    let value = e.target.value;
    console.log('changeListItemThemes ',value);
    S_Datas.changeListItemThemes(this.state.viewItem,this.state.detailsListItem,value,() => {
      this.setState((state) => {
        let list = state.detailsListItem.itemID!==undefined ? state.viewItem.listItems : state.viewItem.listItemsTemporary;
        return {
          lists: S_Datas.lists,
          detailsListItem: S_Datas._getFromList(state.detailsListItem.id,list)
        };
      });
    });
  }
  changeListItemImportant(e) {
    console.log('changeListItemImportant',this.state.view,this.state.viewItem,this.state.detailsListItem);
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    console.log('changeListItemImportant',this.state.detailsListItem);
    S_Datas.toggleListItemImportant(this.state.viewItem,this.state.detailsListItem,() => {
      this.setState((state) => {
        let list = state.detailsListItem.itemID!==undefined ? state.viewItem.listItems : state.viewItem.listItemsTemporary;
        return {
          lists: S_Datas.lists,
          detailsListItem: S_Datas._getFromList(state.detailsListItem.id,list)
        };
      });
    });
  }
  changeListItemQuantity(e) {
    if(this.state.view !== 'list' || !this.state.viewItem)
      return;
    let value = e.target.value;
    S_Datas.changeListItemQuantity(this.state.viewItem,this.state.detailsListItem,value,() => {
      this.setState((state) => {
        let list = state.detailsListItem.itemID!==undefined ? state.viewItem.listItems : state.viewItem.listItemsTemporary;
        return {
          lists: S_Datas.lists,
          detailsListItem: S_Datas._getFromList(state.detailsListItem.id,list)
        };
      });
    });
  }
  deleteAllStorage(e) {
    S_Datas.deleteAllStorage(() => {
      this.setState({
        items: S_Datas.items,
        keywords: S_Datas.keywords,
        tags: S_Datas.tags,
        shelves: S_Datas.shelves,
        lists: S_Datas.lists,
        openHomeMenu: false
      });
    });
  }
  openDetailsItem(e,item) {
    console.log('Open item',item);
    this.setState({
      openItemModal: true,
      detailsItem: item
    });
  }
  closeDetailsItemModal(e) {
    this.stopScan();
    this.setState({
      openItemModal: false,
      detailsItem: undefined
    });
  }
  changeItemName(e) {
    let value = e.target.value;
    S_Datas.changeItemName(this.state.detailsItem,value,() => {
      this.setState((state) => ({
        items: S_Datas.items,
        detailsItem: S_Datas.getItem(state.detailsItem.id)
      }));
    });
  }
  changeItemBarcode(e) {
    let value = e.target.value;
    S_Datas.changeItemBarCode(this.state.detailsItem,value,() => {
      this.setState((state) => ({
        items: S_Datas.items,
        detailsItem: S_Datas.getItem(state.detailsItem.id)
      }));
    });
  }
  changeItemShelves(e) {
    let value = e.target.value;
    console.log('changeItemShelves ',value);
    S_Datas.changeItemShelves(this.state.detailsItem,value,() => {
      this.setState((state) => ({
        items: S_Datas.items,
        detailsItem: S_Datas.getItem(state.detailsItem.id)
      }));
    });
  }
  changeItemTags(e) {
    let value = e.target.value;
    console.log('changeItemTags ',value);
    S_Datas.changeItemTags(this.state.detailsItem,[parseInt(value)],() => {
      this.setState((state) => ({
        items: S_Datas.items,
        detailsItem: S_Datas.getItem(state.detailsItem.id)
      }));
    });
  }
  changeItemPrice(e) {
    let value = e.target.value;
    S_Datas.changeItemPrice(this.state.detailsItem,value,() => {
      this.setState((state) => ({
        items: S_Datas.items,
        detailsItem: S_Datas.getItem(state.detailsItem.id)
      }));
    });
  }
  changeItemPriceUnity(e) {
    let value = e.target.value;
    S_Datas.changeItemPriceUnity(this.state.detailsItem,value,() => {
      this.setState((state) => ({
        items: S_Datas.items,
        detailsItem: S_Datas.getItem(state.detailsItem.id)
      }));
    });
  }
  changeItemMain(e){
    let value = e.target.value;
    S_Datas.changeItemMain(this.state.detailsItem,value,() => {
      this.setState((state) => ({
        items: S_Datas.items,
        detailsItem: S_Datas.getItem(state.detailsItem.id)
      }));
    });
  }
  toggleExpandItem(e,item) {
    if(!item)
      return;
    console.log('toggleExpandItem from',item.expanded,'of',item);
    this.setState((state) => {
      for(var i = 0; i < state.items.length; i++){
        if(state.items[i].id === item.id){
          state.items[i].expanded = item.expanded===undefined ? true : !item.expanded;
          console.log('toggleExpandItem done in state',state.items[i].expanded,state.items[i]);
          break;
        }
      }
      return ({
        items: state.items
      });
    });
  }
  toggleSelectItemDrawer(e) {
    console.log('toggleSelectItemDrawer');
    this.setState((state) => ({
      openSelectItem: !state.openSelectItem
    }));
  }
  addItem(e) {
    S_Datas.newItem(() => {
      this.setState((state) => {
        let newItem = S_Datas.items[S_Datas.items.length - 1];
        console.log('addItem :',newItem);
        return {
          items: S_Datas.items,
          openItemModal: true,
          detailsItem: newItem
        };
      });
    });
  }
  deleteItem(e) {
    if(!this.state.detailsItem)
      return;
    if(S_Datas.isItemInLists(this.state.detailsItem)){
      console.log('Cannot delete product if its in a list');
      return;
    }
      
    S_Datas.deleteItem(this.state.detailsItem,() => {
      this.setState({
        openItemModal: false,
        detailsItem: undefined,
        items: S_Datas.items
      });
    });
  }
  addItemTemporary(e){
    S_Datas.newItemTemporaryToList(this.state.viewItem,() => {
      this.setState((state) => {
        let list = S_Datas.getList(state.viewItem.id);
        let newListItem = list.listItemsTemporary[list.listItemsTemporary.length - 1];
        return {
          lists: S_Datas.lists,
          viewItem: list,
          openListItemModal: true,
          detailsListItem: newListItem
        };
      });
    });
  }
  changeListItemTemporaryName(e) {
    let value = e.target.value;
    S_Datas.changeListItemTemporaryName(this.state.viewItem,this.state.detailsListItem,value,() => {
      this.setState((state) => ({
        lists: S_Datas.lists,
        detailsListItem: S_Datas._getFromList(state.detailsListItem.id,state.viewItem.listItemsTemporary)
      }));
    });
  }
  changeListItemTemporaryShelves(e) {
    let value = e.target.value;
    S_Datas.changeListItemTemporaryShelves(this.state.viewItem,this.state.detailsListItem,value,() => {
      this.setState((state) => ({
        lists: S_Datas.lists,
        detailsListItem: S_Datas._getFromList(state.detailsListItem.id,state.viewItem.listItemsTemporary)
      }));
    });
  }
  changeListItemTemporaryTags(e) {
    let value = e.target.value;
    S_Datas.changeListItemTemporaryTags(this.state.viewItem,this.state.detailsListItem,[parseInt(value)],() => {
      this.setState((state) => ({
        lists: S_Datas.lists,
        detailsListItem: S_Datas._getFromList(state.detailsListItem.id,state.viewItem.listItemsTemporary)
      }));
    });
  }
  changeListItemTemporaryPrice(e) {
    let value = e.target.value;
    S_Datas.changeListItemTemporaryPrice(this.state.viewItem,this.state.detailsListItem,value,() => {
      this.setState((state) => ({
        lists: S_Datas.lists,
        detailsListItem: S_Datas._getFromList(state.detailsListItem.id,state.viewItem.listItemsTemporary)
      }));
    });
  }
  changeListItemTemporaryPriceUnity(e) {
    let value = e.target.value;
    S_Datas.changeListItemTemporaryPriceUnity(this.state.viewItem,this.state.detailsListItem,value,() => {
      this.setState((state) => ({
        lists: S_Datas.lists,
        detailsListItem: S_Datas._getFromList(state.detailsListItem.id,state.viewItem.listItemsTemporary)
      }));
    });
  }

  initScanner(cbSuccess) {
    let self = this;
    // safely access `navigator.mediaDevices.getUserMedia`
    if(!self.state.scannerInitialized){
      console.log('initScanner...');
      try{
        Quagga.init({
          inputStream : {
            name : "Live",
            type : "LiveStream",
            target: document.querySelector('#scanner') 
          },
          decoder : {
            readers : ["ean_reader"]
          }
        }, function(err) {
          if (err) {
            console.log('initScanner...',err);
            self.setState({
              alertScan: err+''
            });
            return;
          }
          self.setState({
            scannerInitialized: true
          });

          console.log("Initialization finished. Ready to start");
          if(cbSuccess)
            cbSuccess();
        });
      }catch(err){
        console.log('initScanner...',err);
        self.setState({
          alertScan: err+''
        });
        return;
      }
    }else{
      if(cbSuccess)
        cbSuccess();
    }
  }
  openScan(e) {
    let self = this;
    if(!this.state.scannerInitialized){
      if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
        this.initScanner(() => {
          Quagga.onDetected((data) => {
            console.log('Detected! ',data);
            let value = data;
            if(value===undefined || value.codeResult===undefined || value.codeResult.code===undefined || value.codeResult.format!=='ean_13')
              return;
            self.pauseScan(null);
            if(self.state.detailsItem!==undefined){
              S_Datas.changeItemBarCode(self.state.detailsItem,value.codeResult.code,() => {
                self.setState((state) => ({
                  items: S_Datas.items,
                  detailsItem: S_Datas.getItem(state.detailsItem.id),
                  openItemModal: false
                }),() => {
                  self.setState({
                    openItemModal: true
                  });
                });
              });
            }
          });
          Quagga.onProcessed((data) => {
            console.log('Processed! ',data);
            let value = data;
            if(value===undefined || value.codeResult===undefined || value.codeResult.code===undefined || value.codeResult.format!=='ean_13')
              return;
            self.pauseScan(null);
            if(self.state.detailsItem!==undefined){
              S_Datas.changeItemBarCode(self.state.detailsItem,value.codeResult.code,() => {
                self.setState((state) => ({
                  items: S_Datas.items,
                  detailsItem: S_Datas.getItem(state.detailsItem.id)
                }));
              });
            }
          });
          // ------------------
          Quagga.start();
          console.log("Started");
          self.setState({
            scannerEnabled: true,
            scannerInitialized: true,
            scannerRunning: true
          });
          console.log('quagga',Quagga);
          // TODO : trouver pk on ne peut pas rouvrir la camera apres un 1er essai
        });
      }
    }else{
      console.log('reRun...');
      self.setState({
        scannerEnabled: true,
        scannerRunning: true
      },() => {
        console.log('restart',Quagga);
        try{
          Quagga.start();
          console.log("Started");
        }catch(err){
          console.error("Error",err);
          self.setState({
            scannerEnabled: false,
            scannerInitialized: false,
            scannerRunning: false,
            alertScan: err+''
          });
        }
      });
    }
  }
  pauseScan(e) {
    // Quagga.offDetected();
    // Quagga.offProcessed();
    Quagga.pause();
    // document.querySelector('#scanner').removeAttribute('style');
    // document.querySelector('#scanner').innerHTML = '';
    this.setState((state) => ({
      scannerRunning: false
    }));
  }
  stopScan(){
    if(!Quagga || !this.state.scannerInitialized)
      return;
    Quagga.offDetected();
    Quagga.offProcessed();
    Quagga.stop();
    this.setState((state) => ({
      scannerInitialized: false,
      scannerRunning: false
    }));
  }
  askOpenFoodFact(e) {
    if(!this.state.detailsItem || !this.state.detailsItem.barcode || this.state.detailsItem.barcode.trim()==='')
      return;
    S_Datas.fetchOpenFoodFact(this.state.detailsItem.barcode,(result) => {
      console.log('askOpenFoodFact',result);
      if(!result || !result.status){
        // TODO display pop error
        return;
      }else{
        let product = result.product;
        let image_url = product.image_front_small_url;
        let quantity = product.quantity;
        let name = product.product_name;
        let gname = product.generic_name
        let brands = product.brands;
        // TODO valider ces donnees
        S_Datas.applyOpenFoodFact(this.state.detailsItem,product,() => {
          this.setState((state) => ({
            items: S_Datas.items,
            detailsItem: S_Datas.getItem(state.detailsItem.id)
          }));
        });
      }

    },() => {
      // TODO display pop error
    });
  }
  closeAlertScan(e){
    this.setState({
      alertScan: undefined
    })
  }


  // STORES ------------------------------
  addStore(e) {
    S_Datas.newStore(() => {
      this.setState((state) => {
        let newStore = S_Datas.stores[S_Datas.stores.length - 1];
        console.log('addStore :',newStore);
        return {
          stores: S_Datas.stores,
          openStoreModal: true,
          detailsStore: newStore,
          displayHomeFabs: false
        };
      });
    });
  }
  deleteStore(e) {
    if(!this.state.detailsStore)
      return;
      
    S_Datas.deleteStore(this.state.detailsStore,() => {
      this.setState({
        openStoreModal: false,
        detailsStore: undefined,
        stores: S_Datas.stores
      });
    });
  }
  openStoreModal(e,store) {
    console.log('Open store',store);
    this.setState({
      openStoreModal: true,
      detailsStore: store
    });
  }
  closeStoreModal(e) {
    console.log('Close store');
    this.setState({
      openStoreModal: false,
      detailsStore: undefined
    });
  }
  changeStoreName(e){
    let value = e.target.value;
    S_Datas.changeStoreName(this.state.detailsStore,value,() => {
      this.setState((state) => ({
        stores: S_Datas.stores,
        detailsStore: S_Datas.getStore(state.detailsStore.id)
      }));
    });
  }
  storeMove(from,to) {
    S_Datas.storeMove(from,to,() => {
      this.setState((state) => {
        return {
          stores: S_Datas.stores
        };
      });
    });
  }

  // SHELVES ------------------------------
  addShelf(e) {
    S_Datas.newShelf(() => {
      this.setState((state) => {
        let newShelf = S_Datas.shelves[S_Datas.shelves.length - 1];
        console.log('addShelf :',newShelf);
        return {
          shelves: S_Datas.shelves,
          openShelfModal: true,
          detailsShelf: newShelf
        };
      });
    });
  }
  addShelfInStore(e,store) {
    S_Datas.newShelfInStore(store,() => {
      this.setState((state) => {
        let newShelf = S_Datas.shelves[S_Datas.shelves.length - 1];
        console.log('addShelf :',newShelf);
        return {
          shelves: S_Datas.shelves,
          openShelfModal: true,
          detailsShelf: newShelf
        };
      });
    });
  }
  deleteShelf(e) {
    if(!this.state.detailsShelf)
      return;
      
    S_Datas.deleteShelf(this.state.detailsShelf,() => {
      this.setState({
        openShelfModal: false,
        detailsShelf: undefined,
        shelves: S_Datas.shelves
      });
    });
  }
  openShelfModal(e,shelf) {
    console.log('Open shelf',shelf);
    this.setState({
      openShelfModal: true,
      detailsShelf: shelf
    });
  }
  closeShelfModal(e) {
    console.log('Close shelf');
    this.setState({
      openShelfModal: false,
      detailsShelf: undefined
    });
  }
  changeShelfName(e){
    let value = e.target.value;
    S_Datas.changeShelfName(this.state.detailsShelf,value,() => {
      this.setState((state) => ({
        shelves: S_Datas.shelves,
        detailsShelf: S_Datas.getShelf(state.detailsShelf.id)
      }));
    });
  }
  changeShelfStore(e){
    let value = e.target.value;
    S_Datas.changeShelfStore(this.state.detailsShelf,value,() => {
      this.setState((state) => ({
        shelves: S_Datas.shelves,
        detailsShelf: S_Datas.getShelf(state.detailsShelf.id)
      }));
    });
  }
  shelfMove(from,to) {
    S_Datas.shelfMove(from,to,() => {
      this.setState((state) => {
        return {
          shelves: S_Datas.shelves
        };
      });
    });
  }
  toggleSortShelves(cb) {
    this.setState((state) => {
      console.log('toggleSortShelves : ',state.sortShelves);
      let newOne = state.sortShelves === undefined ? false : (state.sortShelves === false ? true : undefined);
      return ({
        sortShelves: newOne
      });
    },() => {
      if(cb)
        cb();
    });
  }



  // divers --------------------------------




  toggleFabs() {
    console.log('toggleFabs',this.state.displayHomeFabs);
    this.setState((state) => ({
      displayHomeFabs: !state.displayHomeFabs
    }));
  }
  onToggleListFabs() {
    this.setState((state) => ({
      displayListFabs: !state.displayListFabs
    }));
  }




  render (){
    console.log('Render',this.state);


    // const toggleDrawer = (anchor: Anchor, open: boolean) => (
    //   event: React.KeyboardEvent | React.MouseEvent,
    // ) => {
    //   if (
    //     event &&
    //     event.type === 'keydown' &&
    //     ((event as React.KeyboardEvent).key === 'Tab' ||
    //       (event as React.KeyboardEvent).key === 'Shift')
    //   ) {
    //     return;
    //   }

    //   setState({ ...state, [anchor]: open });
    // };

    if(this.state.view === 'default')
      return (
        <PageLists
          onViewItems={this.viewItems}
          onViewList={this.viewList}
          onViewStores={this.viewStores}
          onViewShelves={this.viewShelves}

          onAddList={(e) => {this.addList(e);this.toggleFabs();}}
          onAddStore={(e) => {this.addStore(e);this.toggleFabs();this.viewStores(e);}}
          onAddShelf={(e) => {this.addShelf(e);this.toggleFabs();this.viewShelves(e);}}
          onAddItem={(e) => {this.addItem(e);this.toggleFabs();this.viewItems(e);}}

          sortLists={this.state.sortLists}
          onListsSortEnd={this.listMove}
          onToggleSortLists={this.toggleSortLists}

          onDeleteAllStorage={this.deleteAllStorage}
          onToggleHomeMenu={this.toggleHomeMenu}
          onCloseHomeMenu={this.closeHomeMenu}
          onToggleFabs={this.toggleFabs}

          open={this.state.openHomeMenu}
          displayFabs={this.state.displayHomeFabs}

          lists={this.state.lists}

          homeMenuAnchorRef={this.homeMenuAnchorRef}
          logo={logo}
          />
      );

    if(this.state.view === 'list')
      return (
        <PageList
          onViewDefault={this.viewDefault}
          onShareList={this.shareCurrentList}
          onToggleMoreMenu={this.toggleMoreMenu}
          onChangeOrderListItems={this.changeOrderListItems}
          onChangeOrderItems={this.changeOrderItems}
          onDeleteListItem={this.deleteListItem}
          onOpenDetailsListItem={this.openDetailsListItem}
          onOpenRenameTheme={this.openRenameTheme}
          onDeleteCurrentListTheme={this.deleteCurrentListTheme}
          onAddThemeToList={this.addThemeToList}
          onToggleSelectItemDrawer={this.toggleSelectItemDrawer}
          onAddItemToList={this.addItemToList}
          onAddItem={(e) => {this.addItem(e);this.onToggleListFabs();}}
          onAddItemTemporary={(e) => {this.addItemTemporary(e);this.onToggleListFabs();}}
          onCloseMoreMenu={this.closeMoreMenu}
          onOpenRenameCurrentList={this.openRenameCurrentList}
          onDeleteCurrentList={this.deleteCurrentList}
          onCloseRenameTheme={this.closeRenameTheme}
          onRenameCurrentList={this.renameCurrentList}
          onCloseRenameListModal={this.closeRenameListModal}
          onRenameCurrentListTheme={this.renameCurrentListTheme}
          onCloseDetailsListItemModal={this.closeDetailsListItemModal}
          onCloseDetailsItemModal={this.closeDetailsItemModal}
          onEmptyCurrentList={this.emptyCurrentList}

          onChangeListItemImportant={this.changeListItemImportant}
          onChangeListItemNotes={this.changeListItemNotes}
          onChangeListItemThemes={this.changeListItemThemes}
          onChangeListItemQuantity={this.changeListItemQuantity}
          onChangeListItemTemporaryName={this.changeListItemTemporaryName}
          onChangeListItemTemporaryShelves={this.changeListItemTemporaryShelves}
          onChangeListItemTemporaryTags={this.changeListItemTemporaryTags}
          onChangeListItemTemporaryPrice={this.changeListItemTemporaryPrice}
          onChangeListItemTemporaryPriceUnity={this.changeListItemTemporaryPriceUnity}

          onDeleteItem={this.deleteItem}
          onChangeItemName={this.changeItemName}
          onChangeItemShelves={this.changeItemShelves}
          onChangeItemTags={this.changeItemTags}
          onChangeItemBarcode={this.changeItemBarcode}
          onChangeItemPrice={this.changeItemPrice}
          onChangeItemPriceUnity={this.changeItemPriceUnity}
          onChangeItemMain={this.changeItemMain}
          toggleExpandItem={this.toggleExpandItem}

          onOpenScan={this.openScan}
          onStopScan={this.stopScan}
          onAskOpenFoodFact={this.askOpenFoodFact}
          onCloseAlertScan={this.closeAlertScan}

          viewItem={this.state.viewItem}
          items={this.state.items}
          orderListItemsBy={this.state.orderListItemsBy}
          orderItemsBy={this.state.orderItemsBy}
          shelves={this.state.shelves}
          shelvesCategories={this.state.shelvesCategories}
          stores={this.state.stores}
          tags={this.state.tags}
          renameTheme={this.state.renameTheme}
          detailsListItem={this.state.detailsListItem}
          detailsItem={this.state.detailsItem}

          openSelectItem={this.state.openSelectItem}
          openMoreMenu={this.state.openMoreMenu}
          openRenameListModal={this.state.openRenameListModal}
          openRenameThemeModal={this.state.openRenameThemeModal}
          openListItemModal={this.state.openListItemModal}
          openItemModal={this.state.openItemModal}
          scannerEnabled={this.state.scannerEnabled}
          scannerInitialized={this.state.scannerInitialized}
          scannerRunning={this.state.scannerRunning}
          alertScan={this.state.alertScan}
          moreMenuAnchorRef={this.moreMenuAnchorRef}

          displayFabs={this.state.displayListFabs}
          onToggleFabs={this.onToggleListFabs}
          />
      );

    if(this.state.view === 'items')
      return (
        <PageItems
          onViewDefault={this.viewDefault}
          onViewStores={this.viewStores}
          onViewShelves={this.viewShelves}
          onChangeOrderItems={this.changeOrderItems}
          onOpenDetailsItem={this.openDetailsItem}
          onAddItem={this.addItem}
          onDeleteItem={this.deleteItem}
          onCloseDetailsItemModal={this.closeDetailsItemModal}
          onChangeItemName={this.changeItemName}
          onChangeItemShelves={this.changeItemShelves}
          onChangeItemTags={this.changeItemTags}
          onChangeItemBarcode={this.changeItemBarcode}
          onChangeItemPrice={this.changeItemPrice}
          onChangeItemPriceUnity={this.changeItemPriceUnity}
          onChangeItemMain={this.changeItemMain}
          onOpenScan={this.openScan}
          onStopScan={this.stopScan}
          onAskOpenFoodFact={this.askOpenFoodFact}
          onCloseAlertScan={this.closeAlertScan}
          toggleExpandItem={this.toggleExpandItem}

          orderItemsBy={this.state.orderItemsBy}
          items={this.state.items}
          shelves={this.state.shelves}
          shelvesCategories={this.state.shelvesCategories}
          stores={this.state.stores}
          tags={this.state.tags}
          openItemModal={this.state.openItemModal}
          detailsItem={this.state.detailsItem}
          scannerEnabled={this.state.scannerEnabled}
          scannerInitialized={this.state.scannerInitialized}
          scannerRunning={this.state.scannerRunning}
          alertScan={this.state.alertScan}
          deleteEnabled={this.state.detailsItem && !S_Datas.isItemInLists(this.state.detailsItem)}
          />
      );

    if(this.state.view === 'stores')
      return (
        <PageStores
          onViewDefault={this.viewDefault}
          onViewItems={this.viewItems}
          onViewShelves={this.viewShelves}
          onAddStore={this.addStore}
          onDeleteStore={this.deleteStore}

          stores={this.state.stores}

          openStoreModal={this.state.openStoreModal}
          onOpenStoreModal={this.openStoreModal}
          onCloseStoreModal={this.closeStoreModal}
          detailsItem={this.state.detailsStore}
          onChangeStoreName={this.changeStoreName}
          />
      );

    if(this.state.view === 'shelves')
      return (
        <PageShelves
          onViewDefault={this.viewDefault}
          onViewItems={this.viewItems}
          onViewStores={this.viewStores}

          onAddStore={this.addStore}
          onAddShelf={this.addShelf}
          onAddShelfInStore={this.addShelfInStore}

          displayFabs={this.state.displayHomeFabs}
          onToggleFabs={this.toggleFabs}

          stores={this.state.stores}
          shelves={this.state.shelves}

          sortShelves={this.state.sortShelves}
          onShelvesSortEnd={this.shelfMove}
          onToggleSortShelves={this.toggleSortShelves}

          openShelfModal={this.state.openShelfModal}
          onOpenShelfModal={this.openShelfModal}
          onCloseShelfModal={this.closeShelfModal}
          detailsShelf={this.state.detailsShelf}
          onChangeShelfName={this.changeShelfName}
          onChangeShelfStore={this.changeShelfStore}
          onDeleteShelf={this.deleteShelf}

          onStoreMove={this.storeMove}

          openStoreModal={this.state.openStoreModal}
          onOpenStoreModal={this.openStoreModal}
          onCloseStoreModal={this.closeStoreModal}
          detailsItem={this.state.detailsStore}
          onChangeStoreName={this.changeStoreName}
          onDeleteStore={this.deleteStore}
          />
      );
  }
}

export default App;
