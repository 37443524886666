import React from 'react';
import PropTypes from 'prop-types';
// import './page-stores.scss';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PlaceIcon from '@material-ui/icons/Place';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CategoryIcon from '@material-ui/icons/Category';

import S_App     from   '../services/App';
import S_Datas   from   '../services/Datas';
import Version   from   '../components/Version';
import DetailsStoreDialog     from   '../components/DetailsStoreDialog';
import AppBarDefault     from   '../components/AppBarDefault';


function PageStores(props) {

  return (
    <>
    <AppBarDefault
      onViewDefault={props.onViewDefault}
      onViewShelves={props.onViewShelves}
      onViewItems={props.onViewItems} />
    <Container className="main">

      <div className="mode-contents">

        { props.stores.length === 0 ? (
        <div className="empty-contents min-height-screen">
          <div className="text">Aucun magasin défini.</div>
          <Button variant="contained" aria-label="Ajouter un magasin" title="Ajouter un magasin" onClick={props.onAddStore}>Ajouter un magasin</Button>
        </div>
        ) : (
        <div className=" min-height-screen">
          <List component="nav" aria-label="Mes magasins">
            { props.stores.map((store,index) => (
            <ListItem button key={store.id} onClick={(e) => props.onOpenStoreModal(e,store)}>
              <ListItemAvatar>
                <Avatar className="MuiAvatar-colorDark">
                  <PlaceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<Typography variant="h5">{store.i18n.fr}</Typography>} />
            </ListItem>
            )) }
          </List>
        </div>
        ) }

        <Fab color="primary" aria-label="Ajouter un magasin" title="Ajouter un magasin" className="fab-right fab-right-1" onClick={props.onAddStore}>
          <AddIcon />
        </Fab>

      </div>

      <Version />
    </Container>
    <DetailsStoreDialog 
      open={props.openStoreModal} 
      onClose={props.onCloseStoreModal} 
      
      onDelete={props.onDeleteStore}
      deleteEnabled={true}
      store={props.detailsItem}
      onChangeName={props.onChangeStoreName}/>
    </>
  );
  
}
  
PageStores.propTypes = {
  onViewDefault: PropTypes.func,
  onViewShelves: PropTypes.func,
  onViewItems: PropTypes.func,
  onAddStore: PropTypes.func,
  onDeleteStore: PropTypes.func,

  stores: PropTypes.array,

  openStoreModal: PropTypes.bool,
  onOpenStoreModal: PropTypes.func,
  onCloseStoreModal: PropTypes.func,
  detailsItem: PropTypes.any,
  onChangeStoreName: PropTypes.func
}; 

export default PageStores;
