import React from 'react';
import PropTypes from 'prop-types';
import './ListItems.scss';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PlaceIcon from '@material-ui/icons/Place';
import AllInboxIcon from '@material-ui/icons/AllInbox';

import S_Datas   from   '../services/Datas';
import Chip from '@material-ui/core/Chip';
import CustomChip   from   '../components/CustomChip';

function compareItems(a, b) {
  if (a.i18n.fr.toLowerCase() > b.i18n.fr.toLowerCase()) return 1;
  if (b.i18n.fr.toLowerCase() > a.i18n.fr.toLowerCase()) return -1;

  return 0;
}

function Items(props) {

  if(!props.items || props.items.length===0)
    return null;

  let itemsSorted = JSON.parse(JSON.stringify(props.items)).sort(compareItems);
  console.log('Stored:',props.items);
  console.log('Sorted:',itemsSorted);

  if(!props.variant || props.variant==='names')
    return (
      <div className="listItems">
        { itemsSorted.map( (item,index) => {

          let tag = S_Datas.getTag(item.tagsID[0]);
          let main = item.mainID!==undefined ? S_Datas.getItem(item.mainID) : undefined;
          if(tag.code!=='general' && main!==undefined)
            return null;
          let subitems = [];
          if(tag.code==='general'){
            for(var i = 0; i < itemsSorted.length; i++){
              let mainID = itemsSorted[i].mainID;
              if(mainID!==undefined && mainID===item.id)
                subitems.push(itemsSorted[i]);
            }
          }
          let tagSpe = S_Datas.getTag(2);

          if(subitems.length===0){
            if(!props.editable){
              return <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="modelToAdd" onClick={(e) => props.onAdd(item)}/>;
            }else{
              return <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="model" onClick={(e) => props.onClick(e,item)}/>;
            }
          }else{
            if(!props.editable){
              return [
                <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="modelToAdd" 
                  onClick={(e) => props.onAdd(item)}
                  onToggle={(e) => props.onToggleExpandItem(e,item)}/>,
                <div key={'s-'+item.id} className="subitems">
                  {item.expanded!==undefined && item.expanded ? subitems.map((subitem,index2) => (
                    <div key={subitem.id} className="subitem"><CustomChip label={subitem.i18n.fr} tag={tagSpe} variant="modelToAdd" onClick={(e) => props.onAdd(subitem)}/></div>
                  )) : null}
                </div>
              ];
            }else{
              return [
                <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="model" 
                  onClick={(e) => props.onClick(e,item)}
                  onToggle={(e) => props.onToggleExpandItem(e,item)}/>,
                <div key={'s-'+item.id} className="subitems">
                  {item.expanded!==undefined && item.expanded ? subitems.map((subitem,index2) => (
                    <div key={subitem.id} className="subitem"><CustomChip label={subitem.i18n.fr} tag={tagSpe} variant="model" onClick={(e) => props.onClick(e,subitem)}/></div>
                  )) : null}
                </div>
              ];
            }
          }
          
        }) }
      </div>
    );

  if(props.variant==='shelves'){
    // console.log(props.shelves);
    let shelvesClone = JSON.parse(JSON.stringify(props.shelves));
    let shelvesSorted = shelvesClone.sort(compareItems);

    // sans shelf
    let noshelfItems = [];
    for(var i = 0; i < itemsSorted.length; i++){
      let item = itemsSorted[i];
      if(item.shelvesID.length===0)
        noshelfItems.push(item);
    }

    // shelves sans store
    let noStoreShelves = [];
    if(props.shelves && props.shelves.length>0){
      for(var i = 0; i < props.shelves.length; i++){
        let shelf = props.shelves[i];
        if(shelf.storeID===undefined || S_Datas.getStore(shelf.storeID)===undefined){
          noStoreShelves.push(shelf);
          continue;
        }
      }
    }
    
    return (
      <div className="listItems">

        {props.stores && props.stores.length>0 ? 
        props.stores.map( (store,index) => (
        <div key={index}>
          <Typography variant="h6"><PlaceIcon />{ store.i18n.fr }</Typography>
          {props.shelves && props.shelves.length>0 ? props.shelves.map( (shelf,index2) => {
            if(shelf.storeID !== store.id)
              return null;
            let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : null;
            let shelfItems = [];
            for(var i = 0; i < itemsSorted.length; i++){
              let item = itemsSorted[i];
              // console.log(item,'vs',shelf.id);
              if(item.shelvesID.indexOf(shelf.id)===-1)
                continue;
              shelfItems.push(item);
            }

            if(shelfItems.length===0)
              return;
            return (
              <ExpansionPanel key={index + '-' + index2}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel'+index + '-' + index2+'b-content'} id={'panel'+index + '-' + index2+'b-header'} >
                  <span className="expansion-icon"><AllInboxIcon /></span>
                  <Typography>{ shelfCategory ? '['+shelfCategory.i18n.fr+'] ' : null }{ shelf.i18n.fr }</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{'flex':'1'}}>
                  { shelfItems.map( (item,index3) => {

                    let tag = S_Datas.getTag(item.tagsID[0]);
                    let main = item.mainID!==undefined ? S_Datas.getItem(item.mainID) : undefined;
                    if(tag.code!=='general' && main!==undefined)
                      return null;
                    let subitems = [];
                    if(tag.code==='general'){
                      for(var i = 0; i < itemsSorted.length; i++){
                        let mainID = itemsSorted[i].mainID;
                        if(mainID!==undefined && mainID===item.id)
                          subitems.push(itemsSorted[i]);
                      }
                    }
                    let tagSpe = S_Datas.getTag(2);

                    if(subitems.length===0){
                      if(!props.editable){
                        return <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="modelToAdd" onClick={() => props.onAdd(item)} />;
                      }else{
                        return <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="model" onClick={(e) => props.onClick(e,item)}/>;
                      }
                    }else{
                      if(!props.editable){
                        return [
                          <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="modelToAdd" 
                            onClick={() => props.onAdd(item)}
                            onToggle={(e) => props.onToggleExpandItem(e,item)}/>,
                          <div key={'s-'+item.id} className="subitems">
                            {item.expanded!==undefined && item.expanded ? subitems.map((subitem,index4) => (
                              <div key={subitem.id} className="subitem"><CustomChip label={subitem.i18n.fr} tag={tagSpe} variant="modelToAdd" onClick={(e) => props.onAdd(subitem)}/></div>
                            )) : null}
                          </div>
                        ];
                      }else{
                        return [
                          <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="model" 
                            onClick={(e) => props.onClick(e,item)}
                            onToggle={(e) => props.onToggleExpandItem(e,item)}/>,
                          <div key={'s-'+item.id} className="subitems">
                            {item.expanded!==undefined && item.expanded ? subitems.map((subitem,index4) => (
                              <div key={subitem.id} className="subitem"><CustomChip label={subitem.i18n.fr} tag={tagSpe} variant="model" onClick={(e) => props.onClick(e,subitem)}/></div>
                            )) : null}
                          </div>
                        ];
                      }
                    }
                  }) }
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          }) : null}
        </div>
        )) : null}

        {noStoreShelves && noStoreShelves.length>0 ? (
        <div>
          <Typography variant="h6">Rayons sans magasin</Typography>
          {noStoreShelves.map( (shelf,index) => {
            let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : null;
            let shelfItems = [];
            for(var i = 0; i < itemsSorted.length; i++){
              let item = itemsSorted[i];
              // console.log(item,'vs',shelf.id);
              if(item.shelvesID.indexOf(shelf.id)===-1)
                continue;
              shelfItems.push(item);
            }

            if(shelfItems.length===0)
              return;
            return (
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel'+index+'b-content'} id={'panel'+index+'b-header'} >
                  <span className="expansion-icon"><PlaceIcon /></span>
                  <Typography>{ shelfCategory ? '['+shelfCategory.i18n.fr+'] ' : null }{ shelf.i18n.fr }</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{'flex':'1'}}>
                  { shelfItems.map( (item,index3) => {

                    let tag = S_Datas.getTag(item.tagsID[0]);
                    let main = item.mainID!==undefined ? S_Datas.getItem(item.mainID) : undefined;
                    if(tag.code!=='general' && main!==undefined)
                      return null;
                    let subitems = [];
                    if(tag.code==='general'){
                      for(var i = 0; i < itemsSorted.length; i++){
                        let mainID = itemsSorted[i].mainID;
                        if(mainID!==undefined && mainID===item.id)
                          subitems.push(itemsSorted[i]);
                      }
                    }
                    let tagSpe = S_Datas.getTag(2);

                    if(subitems.length===0){
                      if(!props.editable){
                        return <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="modelToAdd" onClick={() => props.onAdd(item)}/>;
                      }else{
                        return <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="model" onClick={(e) => props.onClick(e,item)}/>;
                      }
                    }else{
                      if(!props.editable){
                        return [
                          <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="modelToAdd" 
                            onClick={() => props.onAdd(item)}
                            onToggle={(e) => props.onToggleExpandItem(e,item)}/>,
                          <div key={'s-'+item.id} className="subitems">
                            {item.expanded!==undefined && item.expanded ? subitems.map((subitem,index4) => (
                              <div key={subitem.id} className="subitem"><CustomChip label={subitem.i18n.fr} tag={tagSpe} variant="modelToAdd" onClick={(e) => props.onAdd(subitem)}/></div>
                            )) : null}
                          </div>
                        ];
                      }else{
                        return [
                          <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="model" 
                            onClick={(e) => props.onClick(e,item)}
                            onToggle={(e) => props.onToggleExpandItem(e,item)}/>,
                          <div key={'s-'+item.id} className="subitems">
                            {item.expanded!==undefined && item.expanded ? subitems.map((subitem,index4) => (
                              <div key={subitem.id} className="subitem"><CustomChip label={subitem.i18n.fr} tag={tagSpe} variant="model" onClick={(e) => props.onClick(e,subitem)}/></div>
                            )) : null}
                          </div>
                        ];
                      }
                    }
                  }) }
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </div>
        ) : null}

        {noshelfItems && noshelfItems.length>0 ? (
        <div>
          <Typography variant="h6">Produits sans rayon</Typography>
          {noshelfItems.map( (item,index) => {

            let tag = S_Datas.getTag(item.tagsID[0]);
            let main = item.mainID!==undefined ? S_Datas.getItem(item.mainID) : undefined;
            if(tag.code!=='general' && main!==undefined)
              return null;
            let subitems = [];
            if(tag.code==='general'){
              for(var i = 0; i < itemsSorted.length; i++){
                let mainID = itemsSorted[i].mainID;
                if(mainID!==undefined && mainID===item.id)
                  subitems.push(itemsSorted[i]);
              }
            }
            let tagSpe = S_Datas.getTag(2);

            if(subitems.length===0){
              if(!props.editable){
                return <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="modelToAdd" onClick={() => props.onAdd(item)}/>;
              }else{
                return <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="model" onClick={(e) => props.onClick(e,item)}/>;
              }
            }else{
              if(!props.editable){
                return [
                  <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="modelToAdd" 
                    onClick={() => props.onAdd(item)}
                    onToggle={(e) => props.onToggleExpandItem(e,item)}/>,
                  <div key={'s-'+item.id} className="subitems">
                    {item.expanded!==undefined && item.expanded ? subitems.map((subitem,index4) => (
                      <div key={subitem.id} className="subitem"><CustomChip label={subitem.i18n.fr} tag={tagSpe} variant="modelToAdd" onClick={(e) => props.onAdd(subitem)}/></div>
                    )) : null}
                  </div>
                ];
              }else{
                return [
                  <CustomChip key={item.id} label={item.i18n.fr} tag={tag} variant="model" 
                    onClick={(e) => props.onClick(e,item)}
                    onToggle={(e) => props.onToggleExpandItem(e,item)}/>,
                  <div key={'s-'+item.id} className="subitems">
                    {item.expanded!==undefined && item.expanded ? subitems.map((subitem,index4) => (
                      <div key={subitem.id} className="subitem"><CustomChip label={subitem.i18n.fr} tag={tagSpe} variant="model" onClick={(e) => props.onClick(e,subitem)}/></div>
                    )) : null}
                  </div>
                ];
              }
            }
          })}
        </div>
        ) : null}

      </div>
    );
  }
    
}

Items.propTypes = {
  items: PropTypes.array, 
  shelves: PropTypes.array, 
  stores: PropTypes.array, 
  editable: PropTypes.bool,
  variant: PropTypes.string, 
  onClick: PropTypes.func,
  onAdd: PropTypes.func,
  onToggleExpandItem: PropTypes.func
}; 
export default Items;
