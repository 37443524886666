import React from 'react';
import PropTypes from 'prop-types';
// import './page-shelves.scss';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PlaceIcon from '@material-ui/icons/Place';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CategoryIcon from '@material-ui/icons/Category';
import Fade from '@material-ui/core/Fade';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SortIcon from '@material-ui/icons/Sort';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import VisibilityIcon from '@material-ui/icons/Visibility';

import S_App     from   '../services/App';
import S_Datas   from   '../services/Datas';
import Version   from   '../components/Version';
import DetailsShelfDialog     from   '../components/DetailsShelfDialog';
import DetailsStoreDialog     from   '../components/DetailsStoreDialog';
import AppBarDefault     from   '../components/AppBarDefault';

const DragHandle = SortableHandle(() => (
  <IconButton edge="end" aria-label="Réordonner">
    <DragHandleIcon />
  </IconButton>
));

const SortableItem = SortableElement(({shelf}) => {
  if(!shelf)
    return null;
  return (
    <ListItem className="listitem-shelf">
      <ListItemAvatar>
        <Avatar className="MuiAvatar-colorDark">
          <AllInboxIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography variant="h6">{shelf.i18n.fr}</Typography>} />
      <ListItemSecondaryAction>
        <DragHandle />
      </ListItemSecondaryAction>
    </ListItem>
  );
});

const SortableList = SortableContainer(({shelves,store,onSortEnd}) => {
  return (
    <List component="nav" aria-label="Mes rayons" dense={true}>
      {shelves.map((shelf, index) => {
        if(store){
          if(shelf.storeID !== store.id)
            return null;
          return (
            <SortableItem key={`item-${shelf.id}`} index={index} shelf={shelf} />
          );
        } 
        if(!store){
          if(shelf.storeID !== undefined && S_Datas.getStore(shelf.storeID)!==undefined)
            return null;
          return (
            <SortableItem key={`item-${shelf.id}`} index={index} shelf={shelf} />
          );
        }
      })}
    </List>
  );
});


function PageShelves(props) {

  // sans store
  let noStoreShelves = [];
  if(props.shelves && props.shelves.length>0){
    for(var i = 0; i < props.shelves.length; i++){
      let shelf = props.shelves[i];
      if(shelf.storeID===undefined || S_Datas.getStore(shelf.storeID)===undefined){
        noStoreShelves.push(shelf);
        continue;
      }
    }
  }

  return (
    <>
    <AppBarDefault
      onViewDefault={props.onViewDefault}
      onViewStores={props.onViewStores}
      onViewItems={props.onViewItems} />
    <Container className="main">

      <div className="mode-withouttabs listItems small">

        { props.stores.length === 0 && noStoreShelves.length===0 ? (
        <div className="empty-contents min-height-screen">
          <div className="text">Aucun magasin défini.</div>
          <Button variant="contained" aria-label="Ajouter un magasin" title="Ajouter un magasin" onClick={props.onAddStore}>Ajouter un magasin</Button>
        </div>
        ) : (
        <div className="min-height-screen">

          <div className="">
            { props.stores.map((store,index) => {
              let storeShelves = [];
              if(props.shelves && props.shelves.length>0){
                for(var i=0;i< props.shelves.length;i++){
                  if(props.shelves[i].storeID === store.id)
                    storeShelves.push(props.shelves[i]);
                }
              }
              return (
                <ExpansionPanel key={store.id}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel'+index+'b-content'} id={'panel'+index+'b-header'} >
                    <span className="expansion-icon"><PlaceIcon /></span>
                    <Typography>{ store.i18n.fr }<br/><small>{storeShelves.length} rayon(s)</small></Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{'flex':'1'}}>
                      { props.sortShelves !== undefined ? (
                      <div className="store-cartouche">
                        { props.sortShelves === false ? <Button size="small" variant="contained" aria-label="Modifier le magasin" title="Modifier le magasin" onClick={(e) => props.onOpenStoreModal(e,store)}>Modifier le magasin</Button> : null }
                        { index>0 && props.sortShelves ? <Button className="up-down" size="small" variant="outlined" aria-label="Monter" title="Monter" onClick={(e) => props.onStoreMove({oldIndex:index,newIndex:index-1},undefined)}><ArrowUpwardIcon /></Button> : null }
                        { index<props.stores.length-1 && props.sortShelves ? <Button className="up-down" size="small" variant="outlined" aria-label="Descendre" title="Descendre" onClick={(e) => props.onStoreMove({oldIndex:index,newIndex:index+1},undefined)}><ArrowDownwardIcon /></Button> : null }
                        { props.sortShelves === false ? <Button size="small" variant="contained" aria-label="Ajouter un rayon" title="Ajouter un rayon" onClick={(e) => props.onAddShelfInStore(e,store)}>Ajouter un rayon</Button> : null }
                      </div>
                      ) : null }

                      {storeShelves && storeShelves.length>0 ? (
                        <div>
                        {props.sortShelves ? (
                        <SortableList 
                          shelves={props.shelves} 
                          store={store} 
                          onSortEnd={props.onShelvesSortEnd} />
                        ) : (
                        <List component="nav" aria-label="Mes rayons" dense={true}>
                        { storeShelves.map( (shelf,index2) => {
                          return (
                            <ListItem className="listitem-shelf" button key={index+'-'+index2} onClick={(e) => props.onOpenShelfModal(e,shelf)}>
                              <ListItemAvatar>
                                <Avatar className="MuiAvatar-colorDark">
                                  <AllInboxIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={<Typography variant="h6">{shelf.i18n.fr}</Typography>} />
                            </ListItem>
                          );
                        }) }
                        </List>
                        )}
                        </div>
                      ) : null}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            }) }

            {noStoreShelves && noStoreShelves.length>0 ? (
            <ExpansionPanel className="no-store">
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel--b-content'} id={'panel--b-header'} >
                <span className="expansion-icon"><PlaceIcon /></span>
                <Typography><i>Sans magasin</i><br/><small>{noStoreShelves.length} rayon(s)</small></Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{'flex':'1'}}>
                  <div>
                    {props.sortShelves ? (
                    <SortableList 
                      shelves={props.shelves} 
                      onSortEnd={props.onShelvesSortEnd} />
                    ) : (
                    <List component="nav" aria-label="Mes rayons" dense={true}>
                      { noStoreShelves.map( (shelf,index) => {
                        return (
                          <ListItem className="listitem-shelf" button key={index} onClick={(e) => props.onOpenShelfModal(e,shelf)}>
                            <ListItemAvatar>
                              <Avatar className="MuiAvatar-colorDark">
                                <AllInboxIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="h6">{shelf.i18n.fr}</Typography>} />
                          </ListItem>
                        );
                      }) }
                    </List>
                    )}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            ) : null}
          </div>

        </div>
        ) }

        {props.displayFabs ? [
        <Fade key={0} in={props.displayFabs}>
          <Fab color="primary" aria-label="Ajouter un magasin" title="Ajouter un magasin" size="small" className="fab-right fab-right-2" onClick={props.onAddStore}>
            <span className="label">Ajouter un magasin</span>
            <PlaceIcon  />
          </Fab>
        </Fade>,
        <Fade key={1} in={props.displayFabs}>
          <Fab color="primary" aria-label="Ajouter un rayon" title="Ajouter un rayon" size="small" className="fab-right fab-right-3" onClick={props.onAddShelf}>
            <span className="label">Ajouter un rayon</span>
            <AllInboxIcon  />
          </Fab>
        </Fade>,
        <Fade key={2} in={props.displayFabs && props.sortShelves === true}>
          <Fab color="primary" aria-label="Consulter" title="Consulter" size="small" className="fab-right fab-right-4" onClick={(e) => {props.onToggleSortShelves(props.onToggleFabs);}}>
            <span className="label">Consulter<br/><small>Mode en cours : tri</small></span>
            <VisibilityIcon  />
          </Fab>
        </Fade>,
        <Fade key={3} in={props.displayFabs && props.sortShelves === undefined}>
          <Fab color="primary" aria-label="Editer" title="Editer" size="small" className="fab-right fab-right-4" onClick={(e) => {props.onToggleSortShelves(props.onToggleFabs);}}>
            <span className="label">Editer<br/><small>Mode en cours : consultation</small></span>
            <EditIcon  />
          </Fab>
        </Fade>,
        <Fade key={4} in={props.displayFabs && props.sortShelves === false}>
          <Fab color="primary" aria-label="Trier" title="Trier" size="small" className="fab-right fab-right-4" onClick={(e) => {props.onToggleSortShelves(props.onToggleFabs);}}>
            <span className="label">Trier<br/><small>Mode en cours : édition</small></span>
            <SortIcon  />
          </Fab>
        </Fade>
        ] : null}
        <Fab color="primary" aria-label="Afficher les actions" title="Afficher les actions" className="fab-right fab-right-1" onClick={props.onToggleFabs}>
          <AddIcon />
        </Fab>

      </div>

      <Version />
    </Container>
    <DetailsStoreDialog 
      open={props.openStoreModal} 
      onClose={props.onCloseStoreModal} 
      
      onDelete={props.onDeleteStore}
      deleteEnabled={true}
      store={props.detailsItem}
      onChangeName={props.onChangeStoreName}/>
    <DetailsShelfDialog 
      open={props.openShelfModal} 
      onClose={props.onCloseShelfModal} 
      
      onDelete={props.onDeleteShelf}
      deleteEnabled={true}
      shelf={props.detailsShelf}
      stores={props.stores}
      onChangeName={props.onChangeShelfName}
      onChangeStore={props.onChangeShelfStore}/>
    </>
  );
  
}
  
PageShelves.propTypes = {
  onViewDefault: PropTypes.func,
  onViewStores: PropTypes.func,
  onViewItems: PropTypes.func,

  stores: PropTypes.array,
  shelves: PropTypes.array,
  onAddShelf: PropTypes.func,
  onAddShelfInStore: PropTypes.func,
  onAddStore: PropTypes.func,

  sortShelves: PropTypes.bool,      // shelves & stores, si undefined, aucun
  onShelvesSortEnd: PropTypes.func,
  onToggleSortShelves: PropTypes.func,

  openShelfModal: PropTypes.bool,
  onOpenShelfModal: PropTypes.func,
  onCloseShelfModal: PropTypes.func,
  detailsShelf: PropTypes.any,
  onChangeShelfName: PropTypes.func,
  onChangeShelfStore: PropTypes.func,
  onDeleteShelf: PropTypes.func,

  onStoreMove: PropTypes.func,

  openStoreModal: PropTypes.bool,
  onOpenStoreModal: PropTypes.func,
  onCloseStoreModal: PropTypes.func,
  detailsItem: PropTypes.any,
  onChangeStoreName: PropTypes.func,
  onDeleteStore: PropTypes.func,

  displayFabs: PropTypes.bool,
  onToggleFabs: PropTypes.func
}; 

export default PageShelves;
