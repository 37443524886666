import React from 'react';
// import './DetailsListItemDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ListSubheader from '@material-ui/core/ListSubheader';

import AllInboxIcon from '@material-ui/icons/AllInbox';
import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/Place';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import S_Datas   from   '../services/Datas';
import CustomCard   from   '../components/CustomCard';

function compareThemes(a, b) {
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;

  return 0;
}
function compareItems(a, b) {
  if (a.i18n.fr.toLowerCase() > b.i18n.fr.toLowerCase()) return 1;
  if (b.i18n.fr.toLowerCase() > a.i18n.fr.toLowerCase()) return -1;

  return 0;
}

function DetailsListItemDialog(props) {

  console.log('Details list item : ',props.listItem);

  let themesSorted = JSON.parse(JSON.stringify(props.themesOptions)).sort(compareThemes);

  let renderValueMultiSelect = (selected) => {
    // console.log(selected);
    if(!selected)
      return '';
    if(selected.length===0)
      return 'Aucun thème';
    return selected.length + ' thème(s)'; //(selected as string[]).join(', ');
  };
  let renderValueMultiSelect_shelves= (selected) => {
    // console.log(selected);
    if(!selected)
      return '';
    if(selected.length===0)
      return 'Aucun rayon';
    return selected.length + ' rayon(s)'; //(selected as string[]).join(', ');
  };

  if(!props.listItem)
    return null;

  let item = props.listItem.itemID!==undefined ? S_Datas.getItem(props.listItem.itemID) : props.listItem;
  let isTemporary = props.listItem.itemID===undefined;

  let shelvesSorted = JSON.parse(JSON.stringify(props.shelvesOptions)).sort(compareItems);

  let defaultPrice = item ? item.price : 0;
  let defaultPriceUnity = item ? item.priceUnity : '1L';
  
  let quantityValue = props.listItem.quantity;

  // objets theme
  let themes = [];
  if(props.listItem.themesID && props.listItem.themesID.length>0){
    for(var i = 0; i < props.listItem.themesID.length; i++){
      themes.push(S_Datas._getFromList(props.listItem.themesID[i],props.themesOptions));
    }
  }

  // shelves sans store
  let noStoreShelves = [];
  if(props.shelvesOptions && props.shelvesOptions.length>0){
    for(var i = 0; i < props.shelvesOptions.length; i++){
      let shelf = props.shelvesOptions[i];
      if(shelf.storeID===undefined || S_Datas.getStore(shelf.storeID)===undefined){
        noStoreShelves.push(shelf);
        continue;
      }
    }
  }

  return (
    <Dialog fullWidth={true} open={props.open} onClose={props.close} aria-labelledby="detailslistitem-dialog-title">
      <DialogContent>

        { item ? (
        <CustomCard 
          i18n={item.i18n}
          pictures={item.pictures}
          shelvesID={item.shelvesID}
          tagsID={item.tagsID}
          price={item.price}
          priceUnity={item.priceUnity}
          quantity={quantityValue}
          isTemporary={isTemporary}
          isModel={false}
          important={props.listItem.important}
          notes={props.listItem.notes}
          themes={themes}
          mainID={item.itemID}
          />
        ) : null }

        <h2 id="detailslistitem-dialog-title">Modifier le produit dans la liste</h2>

        { item && isTemporary ? (
        <>
        <div className="field">
          <FormControl fullWidth>
            <TextField id="outlined-multiline-static" label="Name" defaultValue={props.nameTmp} variant="outlined" fullWidth onChange={props.onChangeName}/>
          </FormControl>
        </div>
        <div className="field">
          <FormControl variant="outlined" className="formcontrol-multiselect">
            <InputLabel id="chooseshelves-label">Rayons</InputLabel>
            <div className="multiselect">
              <Select labelId="chooseshelves-label" label="Rayons" id="chooseshelves-checkbox" multiple value={props.shelvesValueTmp} onChange={props.onChangeShelves} 
                input={<Input />} renderValue={renderValueMultiSelect_shelves} fullWidth>
                {props.stores && props.stores.length>0 ? props.stores.map((store,index) => {
                  return [
                    <ListSubheader><PlaceIcon />{ store.i18n.fr }</ListSubheader>,
                    props.shelvesOptions && props.shelvesOptions.length>0 ? props.shelvesOptions.map( (shelf,index2) => {
                      if(shelf.storeID !== store.id)
                        return null;
                      let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : null;
                      return (
                        <MenuItem key={'S-'+index + '-' + index2} value={shelf.id}>
                          <Checkbox checked={item ? item.shelvesID.indexOf(shelf.id) > -1 : false} />
                          <ListItemText primary={(shelfCategory ? '['+shelfCategory.i18n.fr+'] ':'')+shelf.i18n.fr} />
                        </MenuItem>
                      );
                    }) : null
                  ];
                }) : null}

                {noStoreShelves && noStoreShelves.length>0 ? [
                  <ListSubheader>Sans magasin</ListSubheader>,,
                  noStoreShelves.map( (shelf,index) => {
                    let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : null;
                    return (
                      <MenuItem key={'W-'+index} value={shelf.id}>
                        <Checkbox checked={item ? item.shelvesID.indexOf(shelf.id) > -1 : false} />
                        <ListItemText primary={(shelfCategory ? '['+shelfCategory.i18n.fr+'] ':'')+shelf.i18n.fr} />
                      </MenuItem>
                    );
                  })
                ] : null}
              </Select>
            </div>
          </FormControl>
        </div>
        <div className="field">
          <FormControl variant="outlined" className="formcontrol-multiselect">
            <InputLabel id="choosetags-label" className="MuiInputLabel-shrink">Tag</InputLabel>
            <div className="multiselect">
              <RadioGroup aria-label="Tags" name="tag" value={props.tagsValueTmp[0]} onChange={props.onChangeTags}>
                {props.tagsOptions.map((tag,index) => (
                  <FormControlLabel key={tag.id} value={tag.id} control={<Radio />} label={tag.i18n.fr} />
                ))}
              </RadioGroup>
            </div>
          </FormControl>
        </div>
        <div className="field">
          <FormControl fullWidth>
            <TextField type="number" id="price" label="Prix moyen en €" value={defaultPrice} variant="outlined" fullWidth onChange={props.onChangePrice}/>
          </FormControl>
          <br/><br/>
          <FormControl fullWidth>
            <TextField type="text" id="priceUnity" label="Par" value={defaultPriceUnity} variant="outlined" fullWidth onChange={props.onChangePriceUnity}/>
          </FormControl>
        </div>
        </>
        ) : null }

        <div className="field">
          <FormControl fullWidth>
            <FormControlLabel
              control={<Switch checked={props.important} onChange={props.onChangeImportant} name="important" inputProps={{ 'aria-label': 'secondary checkbox' }} />}
              label="Important"
            />
          </FormControl>
        </div>
        <div className="field">
          <FormControl fullWidth>
            <TextField id="outlined-multiline-static" label="Notes" multiline rows="4" defaultValue={props.notesDefaultValue} variant="outlined" fullWidth onChange={props.onChangeNotes}/>
          </FormControl>
        </div>
        <div className="field">
          <FormControl variant="outlined" className="formcontrol-multiselect">
            <InputLabel id="choosetheme-label">Thèmes</InputLabel>
            <div className="multiselect">
              <Select labelId="choosetheme-label" label="Thèmes" id="choosetheme-checkbox" multiple value={props.themesValue} onChange={props.onChangeThemes} 
                input={<Input />} renderValue={renderValueMultiSelect} fullWidth>
                {themesSorted.map((theme,index) => (
                  <MenuItem key={theme.id} value={theme.id}>
                    <Checkbox checked={props.listItem ? props.listItem.themesID.indexOf(theme.id) > -1 : false} />
                    <ListItemText primary={theme.name} />
                  </MenuItem>
                ))}
              </Select>
              <div className="list-actions">
                <Button variant="contained" color="primary" onClick={props.onAddThemeToList}><AddIcon />Ajouter un thème</Button>
              </div>
            </div>
          </FormControl>
        </div>
        <div className="field">
          <FormControl fullWidth>
            <TextField type="number" id="quantity" label="Quantités (pour estimation du prix)" value={quantityValue} variant="outlined" fullWidth onChange={props.onChangeQuantity}/>
          </FormControl>
        </div>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog >
  );
    
}

export default DetailsListItemDialog;
