import React from 'react';
import PropTypes from 'prop-types';
// import './page-lists.scss';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DnsIcon from '@material-ui/icons/Dns';
import PlaceIcon from '@material-ui/icons/Place';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CategoryIcon from '@material-ui/icons/Category';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import FastfoodIcon           from '@material-ui/icons/Fastfood';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SortIcon from '@material-ui/icons/Sort';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";

// import S_App     from   '../services/App';
// import S_Datas   from   '../services/Datas';
import Version   from   '../components/Version';
import HomePoper from   '../components/HomePoper';
import AppBarDefault     from   '../components/AppBarDefault';

import './page-lists.scss';

const DragHandle = SortableHandle(() => (
  <IconButton edge="end" aria-label="Réordonner" className="dragButton">
    <DragHandleIcon />
  </IconButton>
));

const SortableItem = SortableElement(({list}) => {
  if(!list)
    return null;
  return (
    <ListItem className="listitem-list">
      <ListItemAvatar>
        <Avatar className="MuiAvatar-colorDark">
          <FormatListBulletedIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography variant="h5">{list.name}</Typography>} secondary={<Typography variant="inherit">{list.listItems.length+list.listItemsTemporary.length} produit(s)</Typography>} />
      <ListItemSecondaryAction>
        <DragHandle />
      </ListItemSecondaryAction>
    </ListItem>
  );
});

const SortableList = SortableContainer(({lists,onSortEnd}) => {
  return (
    <List component="nav" aria-label="Mes listes" dense={true}>
      {lists.map((list, index) => {
        return (
          <SortableItem key={`item-${list.id}`} index={index} list={list} />
        );
      })}
    </List>
  );
});

function PageLists(props) {

  return (
    <>
    <AppBarDefault
      onViewStores={props.onViewStores}
      onViewShelves={props.onViewShelves}
      onViewItems={props.onViewItems} />
    <Container className="main">

      <div className="list-list">
        <List component="nav" aria-label="Mes listes">
          { props.lists.length === 0 ? (
          <div className="empty-contents min-height-screen">
            <div className="text">Commencez par créer une liste</div>
            <Button variant="contained" aria-label="Ajouter une liste" title="Ajouter une liste" onClick={props.onAddList}>Créer une liste</Button>
          </div>
          ) : (
          <div className="min-height-screen">
            {props.sortLists ? (
            <SortableList 
              lists={props.lists} 
              onSortEnd={props.onListsSortEnd} />
            ) : (
            <List component="nav" aria-label="Mes listes" dense={true}>
              { props.lists.map((list,index) => (
              <ListItem button onClick={(e) => props.onViewList(e,list)} key={list.id}>
                <ListItemAvatar>
                  <Avatar className="MuiAvatar-colorDark">
                    <FormatListBulletedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h5">{list.name}</Typography>} secondary={<Typography variant="inherit">{list.listItems.length+list.listItemsTemporary.length} produit(s)</Typography>} />
              </ListItem>
              )) }
            </List>
            )}
          </div>
          ) }
        </List>

        {props.displayFabs ? [
        <Fade key={0} in={props.displayFabs && props.sortLists === true}>
          <Fab color="primary" aria-label="Consulter" title="Consulter" size="small" className="fab-right fab-right-6" onClick={(e) => {props.onToggleSortLists(props.onToggleFabs);}}>
            <span className="label">Consulter<br/><small>Mode en cours : tri</small></span>
            <VisibilityIcon  />
          </Fab>
        </Fade>,
        <Fade key={1} in={props.displayFabs && (props.sortLists === undefined || props.sortLists === false)}>
          <Fab color="primary" aria-label="Editer" title="Ordonner" size="small" className="fab-right fab-right-6" onClick={(e) => {props.onToggleSortLists(props.onToggleFabs);}}>
            <span className="label">Ordonner<br/><small>Mode en cours : consultation</small></span>
            <SortIcon  />
          </Fab>
        </Fade>,
        <Fade key={2} in={props.displayFabs}>
          <Fab color="primary" aria-label="Ajouter une référence" title="Ajouter une référence" size="small" className="fab-right fab-right-5" onClick={props.onAddItem}>
            <span className="label">Ajouter une référence</span>
            <FastfoodIcon  />
          </Fab>
        </Fade>,
        <Fade key={3} in={props.displayFabs}>
          <Fab color="primary" aria-label="Ajouter un rayon" title="Ajouter un rayon" size="small" className="fab-right fab-right-4" onClick={props.onAddShelf}>
            <span className="label">Ajouter un rayon</span>
            <AllInboxIcon  />
          </Fab>
        </Fade>,
        <Fade key={4} in={props.displayFabs}>
          <Fab color="primary" aria-label="Ajouter un magasin" title="Ajouter un magasin" size="small" className="fab-right fab-right-3" onClick={props.onAddStore}>
            <span className="label">Ajouter un magasin</span>
            <PlaceIcon  />
          </Fab>
        </Fade>,
        <Fade key={5} in={props.displayFabs}>
          <Fab color="primary" aria-label="Ajouter une liste" title="Ajouter une liste" size="small" className="fab-right fab-right-2" onClick={props.onAddList}>
            <span className="label">Ajouter une liste</span>
            <FormatListBulletedIcon  />
          </Fab>
        </Fade>
        ] : null}
        <Fab color="primary" aria-label="Afficher les actions" title="Afficher les actions" className="fab-right fab-right-1" onClick={props.onToggleFabs}>
          <AddIcon />
        </Fab>
      </div>

      <Version />

    </Container>
    <HomePoper open={props.open} anchorEl={props.homeMenuAnchorRef.current} onClose={props.onCloseHomeMenu}
      onClick1={props.onViewItems} onClick2={props.onDeleteAllStorage}/>
    </>
  );
  
}

PageLists.propTypes = {
  onViewItems: PropTypes.func,
  onViewList: PropTypes.func,
  onViewStores: PropTypes.func,
  onViewShelves: PropTypes.func,

  onAddList: PropTypes.func,
  onAddStore: PropTypes.func,
  onAddShelf: PropTypes.func,
  onAddItem: PropTypes.func,

  sortLists: PropTypes.bool,
  onListsSortEnd: PropTypes.func,
  onToggleSortLists: PropTypes.func,

  onDeleteAllStorage: PropTypes.func,
  onToggleHomeMenu: PropTypes.func,  
  onCloseHomeMenu: PropTypes.func,
  onToggleFabs: PropTypes.func,

  open: PropTypes.bool,    // true si le HomePopper est ouvert
  displayFabs: PropTypes.bool, // true si les fabs sont affiches

  lists: PropTypes.array,

  homeMenuAnchorRef: PropTypes.any,
  logo: PropTypes.any
}; 

export default PageLists;
