import React from 'react';
// import './HomePoper.scss';

import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DnsIcon from '@material-ui/icons/Dns';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';

function HomePoper(props) {

  return (
    <Popper open={props.open} anchorEl={props.anchorEl} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
          <Paper elevation={3}>
            <ClickAwayListener onClickAway={props.onClose}>
              <MenuList autoFocusItem={props.open} id="more-menu-grow" onKeyDown={props.onClose}>
                <MenuItem onClick={props.onClick1}>
                  <ListItemIcon>
                    <DnsIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">Les produits</Typography>
                </MenuItem>
                <MenuItem onClick={props.onClick2} disabled>
                  <ListItemIcon>
                    <DeleteForeverIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">Supprimer tout</Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
  

export default HomePoper;
