import React from 'react';
import './ListItems.scss';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import PlaceIcon from '@material-ui/icons/Place';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AllInboxIcon from '@material-ui/icons/AllInbox';

import S_Datas   from   '../services/Datas';
import Chip from '@material-ui/core/Chip';
import CustomChip   from   '../components/CustomChip';

function compareThemes(a, b) {
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;

  return 0;
}
function compareItems(a, b) {
  let va = a.i18n ? a.i18n.fr.toLowerCase() : undefined;
  let vb = a.i18n ? b.i18n.fr.toLowerCase() : undefined;
  if (va > vb) return 1;
  if (vb > va) return -1;

  return 0;
}
function compareListItems(a, b) {
  let itema = S_Datas.getItem(a.itemID);
  let itemb = S_Datas.getItem(b.itemID);
  try{
    let va = a.itemID!==undefined ? (itema && itema.i18n!==undefined ? itema.i18n.fr.toLowerCase() : '') : (a.i18n!==undefined ? a.i18n.fr.toLowerCase() : '');
    let vb = b.itemID!==undefined ? (itemb && itemb.i18n!==undefined ? itemb.i18n.fr.toLowerCase() : '') : (b.i18n!==undefined ? b.i18n.fr.toLowerCase() : '');
    if (va > vb) return 1;
    if (vb > va) return -1;
  }catch(err){
    console.error('Cannot sort between',a,b,err);
  }
  return 0;
}

function ListItems(props) {

  if((!props.items || props.items.length===0) && (!props.itemsTmp || props.itemsTmp.length===0))
    return null;

  let itemsSorted = JSON.parse(JSON.stringify(props.items));
  if(props.itemsTmp){
    let itemsTmp = JSON.parse(JSON.stringify(props.itemsTmp));
    itemsSorted = itemsSorted.concat(itemsTmp);
  }
  itemsSorted = itemsSorted.sort(compareListItems);

  if(!props.variant || props.variant==='date')
    return (
      <div className="listItems">
        { props.items.map( (listItem,index) => {
          let item = S_Datas.getItem(listItem.itemID);
          if(!item)
            return;  // item supprime
          let themes = [];
          if(listItem.themesID && listItem.themesID.length>0){
            for(var i = 0; i < listItem.themesID.length; i++){
              themes.push(S_Datas._getFromList(listItem.themesID[i],props.themes));
            }
          }
          return <CustomChip key={index} label={item.i18n.fr} notes={listItem.notes} important={listItem.important} quantity={listItem.quantity} themes={themes} onClick={(e) => props.onDetails(e,listItem)} onDelete={() => props.onDelete(listItem)}/>;
        }) }
        { props.items && props.items.length>0 && props.itemsTmp && props.itemsTmp.length>0 ? <div className="separator"></div> : null }
        { props.itemsTmp.map( (listItem,index) => {
          let themes = [];
          if(listItem.themesID && listItem.themesID.length>0){
            for(var i = 0; i < listItem.themesID.length; i++){
              themes.push(S_Datas._getFromList(listItem.themesID[i],props.themes));
            }
          }
          let quantities = listItem.quantity!==undefined ? listItem.quantity/* + (listItem.priceUnity!==undefined ? ' ' + listItem.priceUnity : '')*/ : '';
          return <CustomChip key={index} label={listItem.i18n.fr} notes={listItem.notes} important={listItem.important} quantity={quantities} themes={themes} isTemp={true} onClick={(e) => props.onDetails(e,listItem)} onDelete={() => props.onDelete(listItem)}/>;
        }) }
      </div>
    );

  if(!props.variant || props.variant==='names')
    return (
      <div className="listItems">
        { itemsSorted.map( (listItem,index) => {
          let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
          if(!item)
            return;  // item supprime
          let themes = [];
          if(listItem.themesID && listItem.themesID.length>0){
            for(var i = 0; i < listItem.themesID.length; i++){
              themes.push(S_Datas._getFromList(listItem.themesID[i],props.themes));
            }
          }
          let quantities = listItem.quantity!==undefined ? listItem.quantity/* + (item.priceUnity!==undefined ? ' ' + item.priceUnity : '')*/ : '';
          return <CustomChip key={index} label={item.i18n.fr} notes={listItem.notes} important={listItem.important} quantity={quantities} themes={themes} isTemp={listItem.itemID===undefined} onClick={(e) => props.onDetails(e,listItem)} onDelete={() => props.onDelete(listItem)}/>;
        }) }
      </div>
    );

  if(props.variant==='shelves'){

    let shelvesSorted = JSON.parse(JSON.stringify(props.shelves)).sort(compareItems);

    // sans shelf
    let noshelfItems = [];
    for(var i = 0; i < itemsSorted.length; i++){
      let listItem = itemsSorted[i];
      let oneitem = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
      if(!oneitem)
        continue;
      if(oneitem.shelvesID.length===0)
        noshelfItems.push(listItem);
    }

    // shelves sans store
    let noStoreShelves = [];
    if(props.shelves && props.shelves.length>0){
      for(var i = 0; i < props.shelves.length; i++){
        let shelf = props.shelves[i];
        if(shelf.storeID===undefined || S_Datas.getStore(shelf.storeID)===undefined){
          noStoreShelves.push(shelf);
          continue;
        }
      }
    }
    
    return (
      <div className="listItems">

        {props.stores && props.stores.length>0 ? 
        props.stores.map( (store,index) => (
        <div key={index}>
          <Typography variant="h6"><PlaceIcon />{ store.i18n.fr }</Typography>
          {props.shelves && props.shelves.length>0 ? props.shelves.map( (shelf,index2) => {
            if(shelf.storeID !== store.id)
              return null;
            let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : null;
            
            let shelfItems = [];
            for(var i = 0; i < itemsSorted.length; i++){
              let listItem = itemsSorted[i];
              let oneitem = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
              if(!oneitem || oneitem.shelvesID.indexOf(shelf.id)===-1)
                continue;
              shelfItems.push(listItem);
            }

            if(shelfItems.length===0)
              return;
            return (
              <ExpansionPanel key={index + '-' + index2}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel'+index + '-' + index2+'b-content'} id={'panel'+index + '-' + index2+'b-header'} >
                  { !props.editable ? <span className="expansion-icon"><AllInboxIcon /></span> : null }
                  <Typography className="expansion-title">{ shelfCategory ? '['+shelfCategory.i18n.fr+'] ' : '' }{ shelf.i18n.fr }</Typography>
                  { !props.editable ? <Chip size="small" label={ shelfItems.length } /> : null }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{'flex':'1'}}>
                  { shelfItems.map( (listItem,index3) => {
                    let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
                    let themes = [];
                    if(listItem.themesID && listItem.themesID.length>0){
                      for(var i = 0; i < listItem.themesID.length; i++){
                        themes.push(S_Datas._getFromList(listItem.themesID[i],props.themes));
                      }
                    }
                    return <CustomChip key={index3} label={item.i18n.fr} notes={listItem.notes} important={listItem.important} quantity={listItem.quantity} themes={themes} isTemp={listItem.itemID===undefined} onClick={(e) => props.onDetails(e,listItem)} onDelete={() => props.onDelete(listItem)}/>;
                  }) }
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          }) : null}
        </div>
        )) : null}

        {noStoreShelves && noStoreShelves.length>0 ? (
        <div>
          <Typography variant="h6">Rayons sans magasin</Typography>
          {noStoreShelves.map( (shelf,index) => {
            let shelfCategory = shelf && shelf.categoryID!==undefined ? S_Datas.getShelfCategory(shelf.categoryID) : null;
            let shelfItems = [];
            for(var i = 0; i < itemsSorted.length; i++){
              let listItem = itemsSorted[i];
              let oneitem = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
              if(!oneitem || oneitem.shelvesID.indexOf(shelf.id)===-1)
                continue;
              shelfItems.push(listItem);
            }

            if(shelfItems.length===0)
              return;
            return (
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel'+index+'b-content'} id={'panel'+index+'b-header'} >
                  <span className="expansion-icon"><PlaceIcon /></span>
                  <Typography className="expansion-title">{ shelfCategory ? '['+shelfCategory.i18n.fr+'] ' : null }{ shelf.i18n.fr }</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{'flex':'1'}}>
                  { shelfItems.map( (listItem,index3) => {
                    let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
                    if(!item)
                      return;  // item supprime
                    let themes = [];
                    if(listItem.themesID && listItem.themesID.length>0){
                      for(var i = 0; i < listItem.themesID.length; i++){
                        themes.push(S_Datas._getFromList(listItem.themesID[i],props.themes));
                      }
                    }
                    let tag = S_Datas.getTag(item.tagsID[0]);
                    return <CustomChip key={index3} label={item.i18n.fr} notes={listItem.notes} important={listItem.important} quantity={listItem.quantity} themes={themes} isTemp={listItem.itemID===undefined} onClick={(e) => props.onDetails(e,listItem)} onDelete={() => props.onDelete(listItem)}/>;
                  }) }
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </div>
        ) : null}

        {noshelfItems && noshelfItems.length>0 ? (
        <div>
          <Typography variant="h6">Produits sans rayon</Typography>
          {noshelfItems.map( (listItem,index) => {
            let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
            if(!item)
              return;  // item supprime
            let themes = [];
            if(listItem.themesID && listItem.themesID.length>0){
              for(var i = 0; i < listItem.themesID.length; i++){
                themes.push(S_Datas._getFromList(listItem.themesID[i],props.themes));
              }
            }
            let tag = S_Datas.getTag(item.tagsID[0]);
            return <CustomChip key={index} label={item.i18n.fr} notes={listItem.notes} important={listItem.important} quantity={listItem.quantity} themes={themes} isTemp={listItem.itemID===undefined} onClick={(e) => props.onDetails(e,listItem)} onDelete={() => props.onDelete(listItem)}/>;
          })}
        </div>
        ) : null}

      </div>
    );
  }

  if(props.variant==='themes'){

    let themesSorted = JSON.parse(JSON.stringify(props.themes)).sort(compareThemes);

    // sans theme
    let nothemeItems = [];
    for(var i = 0; i < itemsSorted.length; i++){
      let listItem = itemsSorted[i];
      if(listItem.themesID.length===0)
        nothemeItems.push(listItem);
    }
    
    return (
      <div className="listItems">
        { themesSorted.map( (theme,index) => {
          // console.log(theme);

          let themeItems = [];
          for(var i = 0; i < itemsSorted.length; i++){
            let listItem = itemsSorted[i];
            let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
            // console.log(listItem,'vs',theme.id);
            if(listItem.themesID.indexOf(theme.id)!==-1)
              themeItems.push(listItem);
          }

          if(themeItems.length===0)
            return (
              <ExpansionPanel key={index} className="expansion-noitem">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel'+index+'a-content'} id={'panel'+index+'a-header'} >
                  { !props.editable ? <span className="expansion-icon"><BookmarkIcon /></span> : null }
                  <Typography className="expansion-title">{ theme.name }</Typography>
                  { !props.editable ? <Chip size="small" label={ themeItems.length } /> : null }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="inherit">Ce thème est vide.</Typography>
                </ExpansionPanelDetails>
                { !props.editable ? (
                <>
                <Divider />
                <ExpansionPanelActions>
                  <Button size="small" color="inherit" onClick={(e) => props.onOpenRenameTheme(e,theme)}><TextFormatIcon />Renommer ce thème</Button>
                  <Button size="small" onClick={(e) => props.onDeleteTheme(e,theme)}><DeleteForeverIcon />Supprimer ce thème</Button>
                </ExpansionPanelActions>
                </>
                ) : null }
              </ExpansionPanel>
            );

          // console.log(theme);
          return (
            <ExpansionPanel key={index}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel'+index+'a-content'} id={'panel'+index+'a-header'} >
                { !props.editable ? <span className="expansion-icon"><BookmarkIcon /></span> : null }
                <Typography className="expansion-title">{ theme.name }</Typography>
                { !props.editable ? <Chip size="small" label={ themeItems.length } /> : null }
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{'flex':'1'}}>
                { themeItems.map( (listItem,index2) => {
                  let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
                  if(!item)
                    return;
                  return <CustomChip key={index2} label={item.i18n.fr} notes={listItem.notes} important={listItem.important} quantity={listItem.quantity} isTemp={listItem.itemID===undefined} onClick={(e) => props.onDetails(e,listItem)} onDelete={() => props.onDelete(listItem)}/>;
                }) }
                </div>
              </ExpansionPanelDetails>
              { !props.editable ? (
              <>
              <Divider />
              <ExpansionPanelActions>
                <Button size="small" color="inherit" onClick={(e) => props.onOpenRenameTheme(e,theme)}><TextFormatIcon />Renommer ce thème</Button>
              </ExpansionPanelActions>
              </>
              ) : null }
            </ExpansionPanel>
          );
        }) }
  
        { nothemeItems.length>0 ? (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panelnoa-content'} id={'panelnoa-header'} >
            <Typography className="expansion-title"><i>Sans thème</i></Typography>
            { !props.editable ? <Chip size="small" label={ nothemeItems.length } /> : null }
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{'flex':'1'}}>
            { nothemeItems.map( (listItem,index) => {
              let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
              if(!item)
                return;
              return <CustomChip key={index} label={item.i18n.fr} notes={listItem.notes} important={listItem.important} quantity={listItem.quantity} isTemp={listItem.itemID===undefined} onClick={(e) => props.onDetails(e,listItem)} onDelete={() => props.onDelete(listItem)}/>;
            }) }
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        ) : null }
      </div>
    );
  }

  if(!props.variant || props.variant==='price'){

    // sans prix
    let nopriceItems = [];
    let priceItems = [];
    for(var i = 0; i < itemsSorted.length; i++){
      let listItem = itemsSorted[i];
      let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
      if(item){
        if(item.price===undefined || item.priceUnity===undefined || listItem.quantity===undefined){
          nopriceItems.push(listItem);
        }else{
          priceItems.push(listItem);
        }
      }
    }

    let total = 0;
    for(var i = 0; i < priceItems.length; i++){
      let listItem = priceItems[i];
      let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
      let quantity = listItem.quantity;
      let price = item.price;
      let priceUnity = item.priceUnity;
      total += quantity * price;
    }
    total = Math.round(total*100)/100;

    return (
      <div className="listItems">

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">Produit</TableCell>
              <TableCell>Q<sup>té</sup></TableCell>
              <TableCell>Prix<span className="priceUnity">Par</span></TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3}><Typography variant="h5" color="primary" align="left">Total</Typography></TableCell>
              <TableCell><Typography variant="h5" color="primary">{total}€</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="left" component="th" scope="row" className="subtitle">Prix estimés</TableCell>
            </TableRow>
            { priceItems.length>0 ? (
              <>
              { priceItems.map( (listItem,index) => {
                let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
                let quantity = listItem.quantity;
                let price = item.price;
                let priceUnity = item.priceUnity;
                return (
                  <TableRow key={index}>
                    <TableCell>{item.i18n.fr}</TableCell>
                    <TableCell>{quantity}</TableCell>
                    <TableCell>{price}€<span className="priceUnity">{priceUnity}</span></TableCell>
                    <TableCell align="right">{quantity * price}€</TableCell>
                  </TableRow>
                );
              }) }
              </>
            ) : null }
          { nopriceItems.length>0 ? (
            <>
            <TableRow>
              <TableCell colSpan={4} align="left" component="th" scope="row" className="subtitle">Produits sans prix</TableCell>
            </TableRow>
            { nopriceItems.map( (listItem,index) => {
              let item = listItem.itemID!==undefined ? S_Datas.getItem(listItem.itemID) : listItem;
              let quantity = listItem.quantity;
              let price = item.price;
              let priceUnity = item.priceUnity;
              return (
                <TableRow key={index}>
                  <TableCell>{item.i18n.fr}</TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>{price ? price + '€' : ''}<span className="priceUnity">{priceUnity}</span></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              );
            }) }
            </>
          ) : null }
          </TableBody>
        </Table>
      </div>
    );
  }
    
    
}
  

export default ListItems;
