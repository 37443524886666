import React from 'react';
import PropTypes from 'prop-types';
// import './page-items.scss';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PlaceIcon from '@material-ui/icons/Place';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CategoryIcon from '@material-ui/icons/Category';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import S_App     from   '../services/App';
import S_Datas   from   '../services/Datas';
import Version   from   '../components/Version';
import ListItems from   '../components/ListItems';
import Items     from   '../components/Items';
import DetailsListItemDialog     from   '../components/DetailsListItemDialog';
import HomePoper from   '../components/HomePoper';
import DetailsItemDialog     from   '../components/DetailsItemDialog';
import AppBarDefault     from   '../components/AppBarDefault';


function PageItems(props) {

  return (
    <>
    <AppBarDefault
      onViewDefault={props.onViewDefault}
      onViewStores={props.onViewStores}
      onViewShelves={props.onViewShelves} />
    <Container className="main">

      { props.stores.length === 0 ? (
      <div className="empty-contents min-height-screen">
        <div className="text">Le catalogue est vide. <br/>Ajoutez des références.</div>
        <Button variant="contained" aria-label="Ajouter une référence" title="Ajouter une référence" onClick={props.onAddItem}>Ajouter une référence</Button>
      </div>
      ) : (
      <div className="min-height-screen">
        <div className="mode-tabs">
          <ButtonGroup aria-label="Affichage" color="primary">
            <Button variant={props.orderItemsBy === 'names' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderItems(e,0)} aria-label="Par nom" title="Par nom"><TextFormatIcon /></Button>
            <Button variant={props.orderItemsBy === 'shelves' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderItems(e,1)} aria-label="Par rayon" title="Par rayon"><PlaceIcon /></Button>
          </ButtonGroup>
        </div>

        <div className="mode-contents">
          { props.orderItemsBy === 'names' ? (
          <div id={'second-tabpanel-0'} aria-labelledby={'second-tab-0'}>
            <Items 
              items={props.items} 
              editable={true} 
              onClick={props.onOpenDetailsItem} 
              onToggleExpandItem={props.toggleExpandItem}/>
          </div>
          ) : null }
          { props.orderItemsBy === 'shelves' ? (
          <div id={'second-tabpanel-1'} aria-labelledby={'second-tab-1'}>
            <Items 
              items={props.items} 
              variant="shelves" 
              shelves={props.shelves} 
              stores={props.stores} 
              editable={true} 
              onClick={props.onOpenDetailsItem} 
              onToggleExpandItem={props.toggleExpandItem}/>
          </div>
          ) : null }

        </div>
      </div>
      ) }

      <Fab color="primary" aria-label="Ajouter une référence" title="Ajouter une référence" className="fab-right fab-right-1 MuiFab-model" onClick={props.onAddItem}>
        <AddIcon />
      </Fab>

      <Version />
    </Container>
    <DetailsItemDialog 
      open={props.openItemModal} 
      onClose={props.onCloseDetailsItemModal} 
      
      onDelete={props.onDeleteItem}
      deleteEnabled={props.deleteEnabled}
      item={props.detailsItem}
      items={props.items}
      stores={props.stores}
      shelves={props.shelves}
      tagsOptions={props.tags}
      shelvesCategories={props.shelvesCategories}

      onChangeName={props.onChangeItemName}
      onChangeShelves={props.onChangeItemShelves}
      onChangeTags={props.onChangeItemTags} 
      onChangeBarCode={props.onChangeItemBarcode} 
      onChangeMain={props.onChangeItemMain}
      onAskScan={props.onOpenScan} scannerEnabled={props.scannerEnabled} scannerInitialized={props.scannerInitialized} scannerRunning={props.scannerRunning} onStopScan={props.onStopScan}
      onAskOpenFoodFact={props.detailsItem && props.detailsItem.barcode!==undefined && props.detailsItem.barcode.trim()!=='' ? props.onAskOpenFoodFact : undefined} 
      alertScan={props.alertScan} onCloseAlertScan={props.onCloseAlertScan}
      onChangePrice={props.onChangeItemPrice} onChangePriceUnity={props.onChangeItemPriceUnity}/>
    </>
  );
  
}
  
PageItems.propTypes = {
  onViewDefault: PropTypes.func,
  onViewStores: PropTypes.func,
  onViewShelves: PropTypes.func,
  onChangeOrderItems: PropTypes.func,
  onOpenDetailsItem: PropTypes.func,
  onAddItem: PropTypes.func,
  onCloseDetailsItemModal: PropTypes.func,  
  onDeleteItem: PropTypes.func,
  onChangeItemName: PropTypes.func,
  onChangeItemShelves: PropTypes.func,
  onChangeItemTags: PropTypes.func,
  onChangeItemBarcode: PropTypes.func,
  onChangeItemMain: PropTypes.func,
  onOpenScan: PropTypes.func,
  onStopScan: PropTypes.func,
  onAskOpenFoodFact: PropTypes.func,
  onCloseAlertScan: PropTypes.func,
  onChangeItemPrice: PropTypes.func,
  onChangeItemPriceUnity: PropTypes.func,
  toggleExpandItem: PropTypes.func,

  orderItemsBy: PropTypes.string, 
  openItemModal: PropTypes.bool,
  scannerEnabled: PropTypes.bool,
  scannerInitialized: PropTypes.bool,
  scannerRunning: PropTypes.bool,
  alertScan: PropTypes.string, 
  deleteEnabled: PropTypes.bool,

  items: PropTypes.array,
  shelves: PropTypes.array,
  shelvesCategories: PropTypes.array,
  stores: PropTypes.array,
  tags: PropTypes.array,
  detailsItem: PropTypes.any,

  homeMenuAnchorRef: PropTypes.any,
  logo: PropTypes.any
}; 

export default PageItems;
