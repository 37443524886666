let DEFAULT_ITEMS = [
  /*{
    'id':1,
    'code':'cheese',
    'i18n':{'fr':'Fromage'},
    'shelvesID':[4],
    'pictures':[],
    'tagsID':[1],
    'keywordsID':[]
  },
  {
    'id':2,
    'code':'bread',
    'i18n':{'fr':'Pain'},
    'shelvesID':[8],
    'pictures':[],
    'tagsID':[1],
    'keywordsID':[]
  },
  {
    'id':3,
    'code':'meat',
    'i18n':{'fr':'Viande'},
    'shelvesID':[10],
    'pictures':[],
    'tagsID':[1],
    'keywordsID':[]
  }*/
];

let DEFAULT_LISTS = [
  /*{
    'id':1,
    'name':'Liste 1',
    'themes':[
      {
        'id':1,
        'name':'Pour la pizza'
      }
    ],
    'listItems':[
      {
        'id': 1,
        'itemID': 1,
        'themesID': [1],         
        'notes': 'Plusieurs fromages',
        'important': true
      }
    ]
  }*/
];

let DEFAULT_KEYWORDS = [
  {
    'id':1,
    'code':'cheese',
    'i18n':{'fr':'Fromage'}
  }
];

let DEFAULT_TAGS = [
  {
    'id':1,
    'code':'general',
    'i18n':{'fr':'Générique'}
  },
  {
    'id':2,
    'code':'specific',
    'i18n':{'fr':'Spécifique'}
  }
];

let DEFAULT_SHELVES = [
  /*{
    'id':1,
    'categoryID':1,
    'storeID':1,
    'i18n':{'fr':'Ménage'}
  },
  {
    'id':2,
    'categoryID':1,
    'i18n':{'fr':'Bébé'}
  },
  {
    'id':3,
    'categoryID':1,
    'i18n':{'fr':'Bio'}
  },
  {
    'id':4,
    'categoryID':1,
    'i18n':{'fr':'Yaourts, Fromages, Laitages'}
  },
  {
    'id':5,
    'categoryID':1,
    'i18n':{'fr':'Charcuterie, Snacks'}
  },
  {
    'id':6,
    'categoryID':1,
    'i18n':{'fr':'Conserves, Pâtes, Riz, Epicerie'}
  },
  {
    'id':7,
    'categoryID':1,
    'i18n':{'fr':'Petit déjeuner'}
  },
  {
    'id':8,
    'categoryID':1,
    'i18n':{'fr':'Gâteaux, Confiserie, Pains'}
  },
  {
    'id':9,
    'categoryID':1,
    'i18n':{'fr':'Boulangerie, Patisserie, Traiteur'}
  },
  {
    'id':10,
    'categoryID':1,
    'i18n':{'fr':'Viandes & Poissons'}
  },
  {
    'id':11,
    'categoryID':1,
    'i18n':{'fr':'Fruits & Légumes'}
  },
  {
    'id':12,
    'categoryID':1,
    'i18n':{'fr':'Surgelés'}
  },
  {
    'id':13,
    'categoryID':1,
    'i18n':{'fr':'Jardinerie & Bricolage'}
  },
  {
    'id':14,
    'categoryID':1,
    'i18n':{'fr':'Ustensiles, Maison, Véhicules'}
  },
  {
    'id':15,
    'categoryID':1,
    'i18n':{'fr':'Loisirs & Jeux'}
  },
  {
    'id':16,
    'categoryID':1,
    'i18n':{'fr':'Santé & Beauté'}
  },
  {
    'id':17,
    'categoryID':1,
    'i18n':{'fr':'Boissons'}
  },
  {
    'id':18,
    'categoryID':1,
    'i18n':{'fr':'Animaux'}
  },
  {
    'id':19,
    'categoryID':1,
    'i18n':{'fr':'Habillement'}
  },
  {
    'id':20,
    'categoryID':3,
    'i18n':{'fr':'Conserves'}
  },
  {
    'id':21,
    'categoryID':3,
    'i18n':{'fr':'Epicerie salée'}
  },
  {
    'id':22,
    'categoryID':3,
    'i18n':{'fr':'Epicerie sucrée'}
  },
  {
    'id':23,
    'categoryID':3,
    'i18n':{'fr':'Boissons'}
  },
  {
    'id':24,
    'categoryID':3,
    'i18n':{'fr':'Hygiène'}
  },
  {
    'id':25,
    'categoryID':3,
    'i18n':{'fr':'Entretien'}
  },
  {
    'id':26,
    'categoryID':3,
    'i18n':{'fr':'Spécial'}
  },
  {
    'id':27,
    'categoryID':3,
    'i18n':{'fr':'Laitage'}
  },
  {
    'id':28,
    'categoryID':3,
    'i18n':{'fr':'Fromage'}
  },
  {
    'id':29,
    'categoryID':3,
    'i18n':{'fr':'Viande'}
  },
  {
    'id':30,
    'categoryID':3,
    'i18n':{'fr':'Volaille'}
  },
  {
    'id':31,
    'categoryID':3,
    'i18n':{'fr':'Pain'}
  },
  {
    'id':32,
    'categoryID':3,
    'i18n':{'fr':'Fruits & Légumes'}
  },
  {
    'id':33,
    'categoryID':3,
    'i18n':{'fr':'Poisson'}
  }*/
];

let DEFAULT_SHELVESCATEGORIES = [
  /*{
    'id':1,
    'i18n':{'fr':'Carrefour'}
  },
  {
    'id':2,
    'i18n':{'fr':'Auchan'}
  },
  {
    'id':3,
    'i18n':{'fr':'Epi\'plette'}
  }*/
];



let DEFAULT_STORES = [
  /*{
    'id':1,
    'i18n':{'fr':'Carrefour'},
    'areas': [
      {
        'id': 1111-1111,
        'i18n':{'fr':'Equipement maison'},
        'shelves':[
          {
            'id' : 22222,
            'i18n':{'fr':'Ustensiles de cuisine'},
          },
          {
            'id' : 22222,
            'i18n':{'fr':'Literie'}
          },
          {
            'id' : 22222,
            'i18n':{'fr':'Salles de bain'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Loisirs'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Habillement'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Bébé'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Jardinerie'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Ménage'}
          }
        ]
      },
      {
        'id': 1111-1112,
        'i18n':{'fr':'Sec'},
        'shelves':[
          {
            'id' : 22222,
            'i18n':{'fr':'Boissons'},
          },
          {
            'id' : 22222,
            'i18n':{'fr':'Conserves'}
          },
          {
            'id' : 22222,
            'i18n':{'fr':'Pâtes riz soupes'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Epicerie'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Petit déj'}
          }
        ]
      },
      {
        'id': 1111-1112,
        'i18n':{'fr':'Frais'},
        'shelves':[
          {
            'id' : 22222,
            'i18n':{'fr':'Laitages'},
          },
          {
            'id' : 22222,
            'i18n':{'fr':'Snacks Charcuterie'}
          },
          {
            'id' : 22222,
            'i18n':{'fr':'Desserts'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Fruits & Légumes'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Viandes & Poissons'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Boulangerie Patisserie'}
          },
          {
            'id': 1111-1112,
            'i18n':{'fr':'Traiteur'}
          }
        ]
      },
      {
        'id': 1111-1112,
        'i18n':{'fr':'Surgelés'},
        'shelves':[
          {
            'id' : 22222,
            'i18n':{'fr':'Viandes & Poissons'},
          },
          {
            'id' : 22222,
            'i18n':{'fr':'Légumes & Poelées'}
          },
          {
            'id' : 22222,
            'i18n':{'fr':'Desserts'}
          }
        ]
      }
    ]
  },
  {
    'id':2,
    'i18n':{'fr':'Auchan'}
  },
  {
    'id':3,
    'i18n':{'fr':'Epi\'plette'}
  }*/
];


export {DEFAULT_ITEMS,DEFAULT_LISTS,DEFAULT_KEYWORDS,DEFAULT_TAGS,DEFAULT_SHELVES,DEFAULT_SHELVESCATEGORIES,DEFAULT_STORES};