import React from 'react';
import PropTypes from 'prop-types';
// import './List.scss';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import ShareIcon from '@material-ui/icons/Share';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PlaceIcon from '@material-ui/icons/Place';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import EuroIcon from '@material-ui/icons/Euro';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CategoryIcon from '@material-ui/icons/Category';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

// import S_App     from   '../services/App';
import S_Datas   from   '../services/Datas';
import Version   from   '../components/Version';
import ListItems from   '../components/ListItems';
import Items     from   '../components/Items';
import DetailsListItemDialog     from   '../components/DetailsListItemDialog';
import DetailsItemDialog         from   '../components/DetailsItemDialog';
import Catalog   from   '../components/Catalog';


function PageList(props) {

  return (
    <>
    <AppBar position="sticky">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="back" onClick={props.onViewDefault}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className="title">{props.viewItem.name}<br/><small>{ (props.viewItem.listItems.length + props.viewItem.listItemsTemporary.length) + ' produit(s)' }</small></Typography>
        <IconButton aria-label="Partager" color="inherit" onClick={props.onShareList}>
          <ShareIcon />
        </IconButton>
        <IconButton aria-label="Plus" color="inherit" ref={props.moreMenuAnchorRef} onClick={props.onToggleMoreMenu}>
          <MoreVertIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Container className="main">

      <div className="min-height-screen">
        <div className="mode-tabs">
          <ButtonGroup aria-label="Affichage" color="primary" >
            <Button variant={props.orderListItemsBy === 'date' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderListItems(e,0)} aria-label="Par date d'ajout" title="Par date d'ajout"><CalendarTodayIcon /></Button>
            <Button variant={props.orderListItemsBy === 'names' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderListItems(e,1)} aria-label="Par nom" title="Par nom"><TextFormatIcon /></Button>
            <Button variant={props.orderListItemsBy === 'shelves' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderListItems(e,2)} aria-label="Par rayon" title="Par rayon"><PlaceIcon /></Button>
            <Button variant={props.orderListItemsBy === 'themes' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderListItems(e,3)} aria-label="Par theme" title="Par thème"><BookmarksIcon /></Button>
            <Button variant={props.orderListItemsBy === 'price' ? 'contained' : 'outlined'} onClick={(e) => props.onChangeOrderListItems(e,4)} aria-label="Par prix" title="Par prix"><EuroIcon /></Button>
          </ButtonGroup>
        </div>

        <div className="mode-contents">
          { props.viewItem.listItems.length === 0 && props.viewItem.listItemsTemporary.length === 0 ? (
          <div className="empty-contents">
            <div className="text">Cette liste est vide. <br/>Ajoutez un produit</div>
            <Button variant="contained" aria-label="Ajouter un produit" title="Ajouter un produit" onClick={props.onToggleSelectItemDrawer}>Ajouter un produit</Button>
          </div>
          ) : (
          <div className="">
            { props.orderListItemsBy === 'date' ? (
            <div id={'simple-tabpanel-0'} aria-labelledby={'simple-tab-0'}>
              <ListItems items={props.viewItem.listItems} itemsTmp={props.viewItem.listItemsTemporary} variant="date" onDelete={props.onDeleteListItem} themes={props.viewItem.themes} onDetails={props.onOpenDetailsListItem}/>
            </div>
            ) : null }
            { props.orderListItemsBy === 'names' ? (
            <div id={'simple-tabpanel-1'} aria-labelledby={'simple-tab-1'}>
              <ListItems items={props.viewItem.listItems} itemsTmp={props.viewItem.listItemsTemporary} variant="names" onDelete={props.onDeleteListItem} themes={props.viewItem.themes} onDetails={props.onOpenDetailsListItem}/>
            </div>
            ) : null }
            { props.orderListItemsBy === 'shelves' ? (
            <div id={'simple-tabpanel-2'} aria-labelledby={'simple-tab-2'}>
              <ListItems items={props.viewItem.listItems} itemsTmp={props.viewItem.listItemsTemporary} variant="shelves" shelves={props.shelves} stores={props.stores} themes={props.viewItem.themes} onDelete={props.onDeleteListItem} onDetails={props.onOpenDetailsListItem}/>
            </div>
            ) : null }
            { props.orderListItemsBy === 'themes' ? (
            <div id={'simple-tabpanel-3'} aria-labelledby={'simple-tab-3'}>
              <ListItems items={props.viewItem.listItems} itemsTmp={props.viewItem.listItemsTemporary} variant="themes" themes={props.viewItem.themes} onDelete={props.onDeleteListItem} onOpenRenameTheme={props.onOpenRenameTheme} onDeleteTheme={props.onDeleteCurrentListTheme} onDetails={props.onOpenDetailsListItem}/>
              <div className="list-actions">
                <Button variant="contained" color="primary" onClick={props.onAddThemeToList}><AddIcon />Ajouter un thème</Button>
              </div>
            </div>
            ) : null }
            { props.orderListItemsBy === 'price' ? (
            <div id={'simple-tabpanel-4'} aria-labelledby={'simple-tab-4'}>
              <ListItems items={props.viewItem.listItems} itemsTmp={props.viewItem.listItemsTemporary} variant="price" themes={props.viewItem.themes}/>
            </div>
            ) : null }
          </div>
          ) }
        </div>
      </div>

      { !props.openSelectItem ? (
        <>
        <Fab color="primary" aria-label="Ajouter un produit" title="Ajouter un produit" className="fab-right fab-right-1" onClick={props.onToggleSelectItemDrawer}>
          <AddIcon />
        </Fab>
        </>
      ) : null }

      <Version />
    </Container>
    <SwipeableDrawer className="selectitem-drawer" anchor={'right'} open={props.openSelectItem} onClose={props.onToggleSelectItemDrawer} onOpen={props.onToggleSelectItemDrawer}>
      <Catalog
        orderItemsBy={props.orderItemsBy}
        onChangeOrderItems={props.onChangeOrderItems}

        items={props.items}
        shelves={props.shelves}
        stores={props.stores}
        onAddItemToList={props.onAddItemToList}
        toggleExpandItem={props.toggleExpandItem}

        displayFabs={props.displayFabs}
        onToggleFabs={props.onToggleFabs}
        onAddItemTemporary={props.onAddItemTemporary}
        onAddItem={props.onAddItem}
        onToggleSelectItemDrawer={props.onToggleSelectItemDrawer} />
    </SwipeableDrawer>
    <Popper open={props.openMoreMenu} anchorEl={props.moreMenuAnchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
          <Paper elevation={3}>
            <ClickAwayListener onClickAway={props.onCloseMoreMenu}>
              <MenuList autoFocusItem={props.openMoreMenu} id="more-menu-grow" onKeyDown={props.onCloseMoreMenu}>
                <MenuItem onClick={(e) => {props.onEmptyCurrentList(e);props.onToggleMoreMenu(e)}}>
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">Vider</Typography>
                </MenuItem>
                <MenuItem onClick={props.onOpenRenameCurrentList}>
                  <ListItemIcon>
                    <TextFormatIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">Renommer</Typography>
                </MenuItem>
                <MenuItem onClick={props.onDeleteCurrentList}>
                  <ListItemIcon>
                    <DeleteForeverIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">Supprimer</Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
    <Dialog  open={props.openRenameListModal} onClose={props.onCloseRenameListModal} aria-labelledby="renamelist-dialog-title">
      <DialogTitle id="renamelist-dialog-title">Renommer la liste</DialogTitle>
      <DialogContent>
        <TextField autoFocus margin="dense" value={props.viewItem.name} id="listname" label="Nom de la liste" type="text" fullWidth onChange={props.onRenameCurrentList}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCloseRenameListModal} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog >
    <Dialog  open={props.openRenameThemeModal} onClose={props.onCloseRenameTheme} aria-labelledby="renametheme-dialog-title">
      <DialogTitle id="renametheme-dialog-title">Renommer le thème</DialogTitle>
      <DialogContent>
        <TextField autoFocus margin="dense" value={props.renameTheme ? props.renameTheme.name : ''} id="themename" label="Nom du thème" type="text" fullWidth onChange={props.onRenameCurrentListTheme}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCloseRenameTheme} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog >
    <DetailsListItemDialog 
      open={props.openListItemModal} 
      onClose={props.onCloseDetailsListItemModal}

      listItem={props.detailsListItem}
      stores={props.stores}
      important={props.detailsListItem ? props.detailsListItem.important : false} onChangeImportant={props.onChangeListItemImportant}
      notesDefaultValue={props.detailsListItem ? props.detailsListItem.notes : ''}  onChangeNotes={props.onChangeListItemNotes}
      themesValue={props.detailsListItem ? props.detailsListItem.themesID : []} onChangeThemes={props.onChangeListItemThemes} themesOptions={props.viewItem.themes} onAddThemeToList={props.onAddThemeToList}
      onChangeQuantity={props.onChangeListItemQuantity}
      nameTmp={props.detailsListItem && props.detailsListItem.itemID===undefined ? props.detailsListItem.i18n.fr : ''} onChangeName={props.onChangeListItemTemporaryName}
      shelvesValueTmp={props.detailsListItem && props.detailsListItem.itemID===undefined ? props.detailsListItem.shelvesID : []} onChangeShelves={props.onChangeListItemTemporaryShelves} shelvesOptions={props.shelves}
      tagsValueTmp={props.detailsListItem && props.detailsListItem.itemID===undefined ? props.detailsListItem.tagsID : []} onChangeTags={props.onChangeListItemTemporaryTags} tagsOptions={props.tags}
      onChangePrice={props.onChangeListItemTemporaryPrice} onChangePriceUnity={props.onChangeListItemTemporaryPriceUnity} />
    <DetailsItemDialog 
      open={props.openItemModal} 
      onClose={props.onCloseDetailsItemModal} 

      onDelete={!S_Datas.isItemInLists(props.detailsItem) ? props.onDeleteItem : undefined}
      item={props.detailsItem}
      stores={props.stores}
      shelves={props.shelves}
      shelvesCategories={props.shelvesCategories} 
      tagsOptions={props.tags}

      onChangeName={props.onChangeItemName}
      onChangeShelves={props.onChangeItemShelves}
      onChangeTags={props.onChangeItemTags} 
      onChangeBarCode={props.onChangeItemBarcode} 
      onChangeMain={props.onChangeItemMain}
      onAskScan={props.onOpenScan} scannerEnabled={props.scannerEnabled} scannerInitialized={props.scannerInitialized} scannerRunning={props.scannerRunning} onStopScan={props.onStopScan}
      onAskOpenFoodFact={props.detailsItem && props.detailsItem.barcode!==undefined && props.detailsItem.barcode.trim()!=='' ? props.onAskOpenFoodFact : undefined} 
      alertScan={props.alertScan} onCloseAlertScan={props.onCloseAlertScan}
      onChangePrice={props.onChangeItemPrice} onChangePriceUnity={props.onChangeItemPriceUnity}/>
    </>
  );
  
}
  
PageList.propTypes = {
  onViewDefault: PropTypes.func,
  onShareList: PropTypes.func,
  onToggleMoreMenu: PropTypes.func,
  onChangeOrderListItems: PropTypes.func,
  onChangeOrderItems: PropTypes.func,
  onDeleteListItem: PropTypes.func,
  onOpenDetailsListItem: PropTypes.func,
  onOpenRenameTheme: PropTypes.func,
  onDeleteCurrentListTheme: PropTypes.func,
  onAddThemeToList: PropTypes.func,
  onToggleSelectItemDrawer: PropTypes.func,
  onAddItemToList: PropTypes.func,
  onAddItem: PropTypes.func,
  onAddItemTemporary: PropTypes.func,
  onCloseMoreMenu: PropTypes.func,
  onOpenRenameCurrentList: PropTypes.func,
  onDeleteCurrentList: PropTypes.func,
  onCloseRenameListModal: PropTypes.func,
  onRenameCurrentList: PropTypes.func,
  onCloseRenameTheme: PropTypes.func,
  onRenameCurrentListTheme: PropTypes.func,
  onCloseDetailsListItemModal: PropTypes.func,
  onEmptyCurrentList: PropTypes.func,

  onChangeListItemImportant: PropTypes.func,
  onChangeListItemNotes: PropTypes.func,
  onChangeListItemThemes: PropTypes.func,
  onChangeListItemTemporaryName: PropTypes.func,
  onChangeListItemTemporaryShelves: PropTypes.func,
  onChangeListItemTemporaryTags: PropTypes.func,
  onChangeListItemTemporaryPrice: PropTypes.func,
  onChangeListItemTemporaryPriceUnity: PropTypes.func,
  onChangeListItemQuantity: PropTypes.func,

  onCloseDetailsItemModal: PropTypes.func,

  onDeleteItem: PropTypes.func,
  onChangeItemName: PropTypes.func,
  onChangeItemShelves: PropTypes.func,
  onChangeItemTags: PropTypes.func,
  onChangeItemBarcode: PropTypes.func,
  onChangeItemPrice: PropTypes.func,
  onChangeItemPriceUnity: PropTypes.func,
  onChangeItemMain: PropTypes.func,
  toggleExpandItem: PropTypes.func,

  onOpenScan: PropTypes.func,
  onStopScan: PropTypes.func,
  onAskOpenFoodFact: PropTypes.func,
  onCloseAlertScan: PropTypes.func,

  orderListItemsBy: PropTypes.string,
  orderItemsBy: PropTypes.string,
  openSelectItem: PropTypes.bool,
  openMoreMenu: PropTypes.bool,
  openRenameListModal: PropTypes.bool,
  openRenameThemeModal: PropTypes.bool,
  openListItemModal: PropTypes.bool,
  openItemModal: PropTypes.bool,
  openScan: PropTypes.bool,
  scannerEnabled: PropTypes.bool,
  scannerInitialized: PropTypes.bool,
  scannerRunning: PropTypes.bool,
  alertScan: PropTypes.string,

  viewItem: PropTypes.any,
  items: PropTypes.array,
  shelves: PropTypes.array,
  shelvesCategories: PropTypes.array,
  stores: PropTypes.array,
  tags: PropTypes.array,
  renameTheme: PropTypes.any,
  detailsListItem: PropTypes.any,
  detailsItem: PropTypes.any,

  moreMenuAnchorRef: PropTypes.any,

  displayFabs: PropTypes.bool,
  onToggleFabs: PropTypes.func
}; 

export default PageList;
